import React, {useState} from "react";
import {useAuth} from "../../../components/AuthProvider";
import {userInvite} from "../../../api/adminApi";
import {Alert, Box, Button, Grid, Snackbar, TextField, Typography, useMediaQuery, useTheme} from "@mui/material";

const InviteUserGrid = ({children}) => {
  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.up('cdLg'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('cdMd'));

  let gridTemplateColumns, gridTemplateAreas;

  if (matchesLg) {
    gridTemplateColumns = 'repeat(12, 1fr)'
    gridTemplateAreas =
      `"invlbl invlbl invtxt invtxt invtxt invtxt invtxt . . . . invbtn"`
  } else if (matchesMd) {
    gridTemplateColumns = 'repeat(6, 1fr)'
    gridTemplateAreas =
      `"invlbl . invtxt invtxt . invbtn"`
  } else {
    gridTemplateColumns = '1fr'
    gridTemplateAreas =
      `"invlbl"
       "invtxt"
       "invbtn"`
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: gridTemplateColumns,
        gap: 2,
        gridTemplateRows: 'auto',
        gridTemplateAreas: gridTemplateAreas,
        alignItems: "center"
      }}
    >
      {children}
    </Box>)
}

const SendInvite = () => {
  const [email, setEmail] = useState("");
  const [notification, setNotification] = useState(false);
  const { token } = useAuth();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setEmail("")
    setNotification(false)
  };

  const handleSendInvite = async (e) => {
    e.preventDefault();
    // Send API request to send email invite

    try {
      const res = await userInvite(token, email.toLowerCase().trim());
      if (res.status === 200) {
        setNotification(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{pb: "20px"}}>
      <form onSubmit={handleSendInvite}>
        <InviteUserGrid>

          <Grid item sx={{gridArea: "invlbl"}}>
            <Typography>
              Send Invite to User
            </Typography>
          </Grid>

          <Grid item sx={{gridArea: "invtxt"}}>
            <TextField
              fullWidth
              required
              type="email"
              variant="outlined"
              placeholder="Email address"
              label="user@example.com"
              value={email}
              onChange={handleEmailChange}
            />
          </Grid>

          <Grid item sx={{gridArea: "invbtn"}}>
            <Button
              variant="contained"
              fullWidth
              sx={{paddingBlock: "14px"}}
              type={"submit"}
            >
              <Typography variant={"buttonFace"}>Send</Typography>
            </Button>
          </Grid>

        </InviteUserGrid>
      </form>

      {notification && (
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          open={notification}
          autoHideDuration={4000}
          onClose={handleClose}>

          <Alert severity="success">Invite sent to {email}.</Alert>

        </Snackbar>
      )}
    </Box>
  )
};

export default SendInvite;
