import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridActionsCellItem} from '@mui/x-data-grid';
import {useAuth} from "../../components/AuthProvider";
import {
  createNewTargetList,
  deleteTargetList,
  getTargetLists,
  isListNameAvailable,
  updateTargetListDescriptors
} from "../../api/list";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography} from "@mui/material";
import {Add} from "@mui/icons-material";

export default function TargetListInventory(props) {
  const {
    onSelectionChange = () => {},
    membershipChangeCount = 0,
    headerText = "My Lists"
  } = props;

  const [reloadCount, setReloadCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [possibleNewName, setPossibleNewName] = useState("");
  const [isNewNameButtonDisabled, setIsNewNameButtonDisabled] = useState(false);
  const {token} = useAuth();

  const delay = ms => new Promise((resolve) => setTimeout(resolve, ms));

  const columns = [
    { field: 'id', headerName: 'ID', width: 150, visible: false },
    {
      field: 'listName',
      headerName: 'List Name',
      flex: 1,
      minWidth: 150,
      editable: true,
    },
    {
      field: 'listDescription',
      headerName: 'Description',
      flex: 2,
      minWidth: 150,
      editable: true,
    },
    {
      field: 'companyCount',
      headerName: 'Companies',
      type: 'number',
      flex: 1,
      maxWidth: 150,
      minWidth: 50,
      editable: false,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      type: 'dateTime',
      flex: .75,
      minWidth: 150,
      editable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const gridEncode = (item) => {
    return (
      {
        id: item._id,
        listName: item?.name,
        listDescription: item?.description,
        companyCount: Array.isArray(item?.companies) ? item.companies.length : 0,
        createdAt: item?.created_at ? new Date(`${item.created_at}Z`) : null,
      }
    )
  }

  const getListInventoryRows = () => {
    if (Array.isArray(rows)) {
      return rows.map(gridEncode)
    } else {
      console.error("Malformed target list inventory data.");
      return []
    }
  }

  const updateListDescriptive = (newRow) => {
    updateTargetListDescriptors(
      token,
      newRow.id,
      newRow.listName,
      newRow.listDescription
    ).then(() => {})

    delay(100).then(() => {setReloadCount(reloadCount + 1)});

    return newRow
  }

  useEffect(() => {
    getTargetLists(token).then( (res) => {
      if (res) {
        if (res?.status === 200) {
          if (Array.isArray(res?.data)) {
            setRows(res?.data);
          }
        } else {

        }
      }
    })
  }, [reloadCount, membershipChangeCount]);

  useEffect(() => {
    async function processNewNameButtonState() {
      if (possibleNewName && possibleNewName.length > 0) {
        isListNameAvailable(token, possibleNewName).then((isAvailable) => {
            if (isAvailable.data === true) {
              setIsNewNameButtonDisabled(false)
            } else if (isAvailable.data === false) {
              setIsNewNameButtonDisabled(true)
            } else {
              setIsNewNameButtonDisabled(true)
            }
          }
        )
      } else {
        setIsNewNameButtonDisabled(true)
      }
    }
    processNewNameButtonState();
  }, [possibleNewName]);

  // Reference: 54d6dd298cef4a29
  function handleNewListClick() {
    createNewTargetList(token, possibleNewName, "(Double-click to edit Description)")
      .then(() => {})

    delay(500).then(() => {setReloadCount(reloadCount + 1)});
  }

  const handleDeleteClick = (id) => () => {
    deleteTargetList(token, id)
      .then(setReloadCount(reloadCount + 1))
  }

  return (
    <>
      <Box sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" , alignItems: 'baseline'}}>
        <Typography variant={"h4"}>{headerText}</Typography>
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: 'flex-end',
          alignItems: 'baseline',
        }}>
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            <InputLabel size="small">New List Name</InputLabel>
            {/* "key" property, reference: 54d6dd298cef4a29 */}
            <OutlinedInput
              key={`new-name-input-search-number-${reloadCount}`}
              id="new-list-name-input"
              label={"New List Name"}
              size="small"
              onChange={(event) => setPossibleNewName(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="add new list by name"
                    onClick={() => {handleNewListClick()}}
                    disabled={isNewNameButtonDisabled}
                    edge="end"
                  >
                    {<Add/>}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
      </Box>
      <Box sx={{height: "302px", width: '100%'}}>
        <DataGrid
          rowHeight={35}
          // hideFooterSelectedRowCount={true}
          // hideFooterPagination={true}
          hideFooter={true}
          rows={getListInventoryRows()}
          onRowSelectionModelChange={
            (x) => {
              onSelectionChange(x)
            }
          }
          columns={columns}
          // scrollbarSize={0}
          // sx={{'& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {display: 'none' }}}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
            sorting: {
              sortModel: [{ field: 'createdAt', sort: 'desc' }],
            },
          }}
          pageSizeOptions={[5]}
          processRowUpdate={(updatedRow) => updateListDescriptive(updatedRow)}
          onProcessRowUpdateError={(error) => console.log(error)}
        />
      </Box>
    </>

  )
}