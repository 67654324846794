import React, {useState} from "react";
import {Alert, Button, Grid, Snackbar, TextField, Typography,} from "@mui/material";
import styled from "@emotion/styled";
import {useNavigate} from "react-router-dom";
import {forgotPasswordApi} from "../../api/authApi";
import LoginLeftPart from "../../components/LoginLeftPart";
import "./style.css";
import LabelText from "../../components/Common/LabelText";
import TextFieldBox from "../../components/Common/TextFieldBox";
import {validateEmail} from "../../helper/validation";

export const StyledTypography = styled(Typography)(() => ({
  color: "#000000",
  fontWeight: "700",
  fontStyle: "normal",
  fontSize: "30px",
  lineHeight: "38px",
}));

styled(TextField)(() => ({
  border: "1px solid  rgb(88, 89, 91,0.7)",
  borderRadius: "6px",
}));

styled(Grid)(() => ({
  margin: "auto",
  display: "flex",
  maxWidth: "411px",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
}));

const LoginTypography = styled(Typography)(() => ({
  color: "#FFFFFF",
  fontWeight: 600,
  fontSize: "16px",
  textTransform: "initial",
}));

const MainContainer = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

const LoginText = styled(Typography)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "33px",
  color: "#4A2CF5",
  cursor: "pointer",
}));

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {
      email: validateEmail(email),
    };
    setFormErrors(errors);
    if (!errors?.email?.length) {
      const res = await forgotPasswordApi(email.toLowerCase().trim());
      if (res) {
        if (res.status === 400) {
          setData(res?.data);
          setOpen(true);
        }
        if (res.status === 200) {
          window.confirm("Please check your e-mail for instructions on resetting your password.")
          navigate("/")
        }
      }
    }
  };

  console.log(formErrors, "formErrors==>");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <MainContainer>
      <Grid
        style={{
          margin: "auto",
          display: "flex",
          maxWidth: "411px",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="forgot_div">
          <div className="wrap_login_first_part">
            <Typography variant="mainText" mb={"11px"} component={"p"}>
              Forgot Password
            </Typography>
            <Typography variant="mainSubText" component={"p"} mb={"51px"}>
              Enter your email and we’ll send you a link to reset your password.
            </Typography>
            <Grid>
              <form onSubmit={handleSubmit}>
                <Grid mb={"14px"}>
                  <LabelText label={"Email address"} isRequired={true} />
                </Grid>
                <TextFieldBox
                  variant="outlined"
                  type="email"
                  placeholder={"Email address"}
                  name={"email"}
                  value={email}
                  handleChange={handleEmailChange}
                  fullWidth
                  error={formErrors}
                />
                {/* <StyledTextField
                  fullWidth
                  required
                  type="email"
                  variant="outlined"
                  placeholder="Email address"
                  value={email}
                  onChange={handleEmailChange}
                /> */}
                <Grid mt={"33px"}>
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    className="loginButton"
                  >
                    <LoginTypography>Send link</LoginTypography>
                  </Button>
                </Grid>
                <LoginText onClick={() => navigate("/")} variant="h6">
                  Back to Login
                </LoginText>
              </form>
              <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
              >
                {data?.msg === "Email is not register Please register" ? (
                  <Alert severity="error">Unregistered email!</Alert>
                ) : (
                  <Alert severity="success">Request sent.</Alert>
                )}
              </Snackbar>
            </Grid>
          </div>
        </div>
      </Grid>
      <LoginLeftPart />
    </MainContainer>
  );
};
