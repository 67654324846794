import styled from "@emotion/styled";
import {Box, Grid, Typography} from "@mui/material";
import React from "react";
import MainLayout from "../../components/layouts/MainLayout";
import SubHeader from "../../components/layouts/SubHeader";
import AdminTabs from "./AdminTabs";

const Wrapper = styled(Grid)(() => ({
  padding: "103px 0px 0px 0px",
  //  background: "#F2F3F4",
  "@media (max-width: 900px)": {
    // Styles for screens with a maximum width of 768px
    padding: "66px 0px 0px 0px",
  },
}));



export default function Admin() {


  return (
    <MainLayout>
      <Wrapper>
        <SubHeader/>
        <Box sx={{paddingX: "100px", paddingTop: "50px"}}>
          <Typography
            variant={"h2"}
            sx={{
              pb: "30px"
            }}
          >
            Administrative Information & Actions
          </Typography>
          <AdminTabs/>
        </Box>
      </Wrapper>
    </MainLayout>
  )
}