import {Avatar, Box, Card, Switch, Tooltip, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {PersonPropTypes} from "../../schema/person";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import SyncIcon from '@mui/icons-material/Sync';
import React from "react";
import MultiSourceDataPointChip from "./MultiSourceDataPointChip";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const TooltipTypography = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 16,
}));


const CardBoundary = styled(Card)(() => ({
  border: "1px solid rgba(88, 89, 91, 0.2)",
  borderRadius: "8px",
  width: "500px",
  // maxHeight: "300px",
  // overflowY: "scroll",
  padding: "15px",
  transition: "transform 0.15s ease-in-out",
  "&:hover": {
    // transform: "scale3d(1.01, 1.01, 1)",
    // background: "#fafafa"
    // border: "1px solid rgba(88, 89, 91, 0.4)",
  },
}));

const PersonCard = (props) => {

  return (
    <CardBoundary variant="outlined" sx={{mb: "10px"}}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: '55px repeat(4, 1fr)',
          gridTemplateAreas: `"hs name name name sync"
          "hs title title title sync"
          "email email email phone phone"`
        }}
      >
        <Box sx={{gridArea: 'hs'}}>
          {props.imageUrl ? (
            <Avatar variant="rounded" src={props.imageUrl}>
            </Avatar>
          ) : (
            <Avatar variant="rounded">
              <PersonIcon />
            </Avatar>
          )
        }
        </Box>

        <Box sx={{gridArea: 'name'}}>
          <Typography variant={"h6"}>
            {props.fullName ? props.fullName : "(Generic Contact Information)"}
          </Typography>
        </Box>

        <Box sx={{display: "flex", gridArea: 'sync', justifyContent: "right", alignItems: "center"}}>
          <Tooltip
            title={
              <TooltipTypography>
                {`CRM Sync`}
              </TooltipTypography>
            }
            placement={"top"}
            arrow
          >
            <SyncIcon/>
          </Tooltip>
          <Switch sx={{margin: "0px"}} disabled={true}/>
        </Box>


        {props?.title &&
          <Box sx={{gridArea: 'title'}}>
            <Typography variant={"body1"}>
              {props.title}
            </Typography>
          </Box>
        }

        {props?.email &&
          <Box sx={{display: "flex", gridArea: 'email', mt: "10px", justifyContent: "left"}}>
            <MultiSourceDataPointChip
              dataPointName={"email"}
              primaryDataPoint={props?.email}
              maxDataWidth={"175px"}
              mr={"8px"}
              iconRenderer={
                (props) => {
                  return (
                    <EmailIcon
                      sx={{
                        color: props.color,
                        height: props.height,
                        width: props.width
                      }}
                    />
                  )
                }
              }
            />
          </Box>
        }

        {props?.phone &&
          <Box sx={{display: "flex", gridArea: 'phone', mt: "10px", justifyContent: "right"}}>
            <MultiSourceDataPointChip
              dataPointName={"phone"}
              primaryDataPoint={props?.phone}
              maxDataWidth={"125px"}
              iconRenderer={
                (props) => {
                  return (
                    <LocalPhoneIcon
                      sx={{
                        color: props.color,
                        height: props.height,
                        width: props.width
                      }}
                    />
                  )
                }
              }
            />
          </Box>
        }
      </Box>
    </CardBoundary>
  )
}

PersonCard.propTypes = PersonPropTypes


export default PersonCard