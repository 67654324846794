import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport,
  GridToolbarFilterButton
} from "@mui/x-data-grid";
import * as React from "react";
import {useEffect, useState} from "react";
import {getEvents} from "../../api/site";
import {useAuth} from "../../components/AuthProvider";
import {Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

const columns = [
    { field: '_id', headerName: 'Event Id', width: 230, visible: false },
    {
      field: 'user_id',
      headerName: 'User Id',
      width: 230,
      editable: false,
    },
    {
      field: 'resource_id',
      headerName: 'Resource Id',
      width: 230,
      editable: false,
    },
    {
      field: 'event_type',
      headerName: 'Event Type',
      flex: 0.75,
      editable: false,
    },
    {
      field: 'resource_type',
      headerName: 'Resource Type',
      flex: 0.75,
      editable: false,
    },
    {
      field: 'initiated_at',
      headerName: 'Initiated At',
      type: 'dateTime',
      flex: 0.75,
      editable: false,
      valueGetter: (value) => new Date(value.value)
    },
    {
      field: 'host',
      headerName: 'Host',
      flex: 0.75,
      editable: false,
    },
    {
      field: 'data',
      headerName: 'Data/Notes',
      flex: 0.75,
      editable: false,
    },
  ]


const EventTable = () => {
  const [eventData, setEventData] = useState([]);
  const [loading] = useState(false)
  const [lookbackHours, setLookbackHours] = useState(72)

  const {token} = useAuth();


  useEffect(() =>{
    const loadEvents = () => {
      getEvents(token, lookbackHours).then((response) => {
        if (response?.status === 200) {
          setEventData(response?.data)
        } else {
          console.log(response)
        }
      })
    }
    loadEvents()
  }, [lookbackHours])

  const handleLookbackChange = (e) => {
    setLookbackHours(e.target.value)
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{pl: "10px"}}>
        <Select
          id="select-lookback-select"
          value={lookbackHours}
          onChange={handleLookbackChange}
          variant={"standard"}
          size={"small"}
        >
          <MenuItem value={1}>1 Hour</MenuItem>
          <MenuItem value={24}>24 Hours</MenuItem>
          <MenuItem value={72}>72 Hours</MenuItem>
        </Select>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector/>
        <GridToolbarExport/>
      </GridToolbarContainer>
    );
  }


  return (
    <DataGrid
      rowHeight={35}
      rows={eventData}
      loading={loading}
      columns={columns}
      getRowId={(row) => row._id}
      initialState={{
        sorting: {
          sortModel: [{ field: 'initiated_at', sort: 'desc' }],
        },
        pagination: {
          paginationModel: {
            pageSize: 20,
          }
        }
      }}
      slots={{
        toolbar: CustomToolbar,
      }}
      slotProps={{loadingOverlay: {variant: 'skeleton'} }}
    />
  )
}

export default EventTable