import {Avatar, Card, CardActions, CardContent, Divider, Grid, Tooltip, Typography} from "@mui/material";
import React from "react";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import LocationViz from "./LocationViz";
import {Link} from "react-router-dom";
import styled from "@emotion/styled";
import {EmployeeFormatter} from "../../helper/formatting";
import PropTypes from "prop-types";

const TooltipTypography = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 16,
}));

function MetricCard(props) {
    const {
        metricName,
        iconRenderer,  // supply (settings) => Icon/SVGIcon
        pointEstimate,
        lowerBound,
        upperBound,
        dataPoints,
        iconBackground = "#bdbdbd",
        documentationLink = "#",
        numberFormatter = EmployeeFormatter,
        height = "255px",
        width = "275px",
        minWidth = "275px"
    } = props

    const formattedLowerBound = numberFormatter.format(lowerBound ? lowerBound : 0);
    const formattedUpperBound = numberFormatter.format(upperBound);
    const formattedPointEstimate = numberFormatter.format(pointEstimate);
    const formattedRangeString = "(" + formattedLowerBound + " - " + formattedUpperBound + ")"

    const isCardValid = !(typeof pointEstimate === 'undefined' || typeof upperBound === 'undefined');

    return (
        <Card
            variant={"outlined"}
            sx={{
                width: width,
                minWidth: minWidth,
                height: height,
                textAlign: "center",
                borderRadius: "8px",
                flexShrink: "0"
            }}>
            <CardContent>
                <Typography variant="h5" gutterBottom>
                    {metricName}
                </Typography>
                <Divider variant="middle" sx={{marginTop: "16px", marginBottom: "16px"}}/>
                <Grid container spacing={2} sx={{alignItems: "center", justifyContent: "center"}}>
                    <Grid item xs={3}>
                        <Avatar sx={{textAlign: "center",  width: 55, height: 55, background: iconBackground}}>
                            {iconRenderer({width: 30, height: 30})}
                        </Avatar>
                    </Grid>
                    <Grid item xs={7}>
                      { isCardValid
                        ?
                          <>
                            <Typography variant={"h1"}>{formattedPointEstimate}</Typography>
                            <Typography variant={"h6"}>{formattedRangeString}</Typography>
                          </>
                        :
                          <Typography variant={"h1"}>{"N/A"}</Typography>
                      }
                    </Grid>
                </Grid>
                <Divider variant="middle" sx={{marginTop: "16px", marginBottom: "0px"}}/>
                <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center">
                      { isCardValid
                        ?
                          <Grid item>
                              <LocationViz {...{
                                  lowerBound: lowerBound,
                                  pointEstimate: pointEstimate,
                                  upperBound: upperBound,
                                  dataPoints: dataPoints,
                                  width: `calc(${width} * 0.8)`,
                                  formatter: numberFormatter
                              }}/>
                          </Grid>
                        :
                          <Grid item/>
                      }
                </Grid>
            </CardContent>
            <Divider sx={{marginTop: "0px", marginBottom: "0px"}}/>
            <CardActions sx={{alignItems: "right", justifyContent: "right"}}>
                <Tooltip
                    title={<TooltipTypography>{"Documentation"}</TooltipTypography>}
                    placement={"top"}
                    arrow
                >
                    <Link to={documentationLink}>
                        <Avatar
                            sx={{
                                textAlign: "center",
                                width: 20,
                                height: 20,
                                color: "#6D6968",
                                background: "#eeeeee",
                                webkitTransition: "font-size 2s",
                                transition: "font-size 2s",
                                '&:hover': {
                                    color: "white",
                                    background: "#4A2CF5"
                                }
                            }}>
                            <QuestionMarkIcon sx={{width: 12, height: 12}}/>
                        </Avatar>
                    </Link>
                </Tooltip>
            </CardActions>
        </Card>
    )

}

MetricCard.propTypes = ({
  metricName: PropTypes.string,
  iconRenderer: PropTypes.func,
  pointEstimate: PropTypes.number,
  lowerBound: PropTypes.number,
  upperBound: PropTypes.number,
  dataPoints: PropTypes.number,
  iconBackground: PropTypes.any,
  documentationLink: PropTypes.string,
  numberFormatter: PropTypes.func,
  height: PropTypes.any,
  width: PropTypes.any,
})

export default MetricCard;