import React, {useState} from "react";
import {Button, Grid, Typography,} from "@mui/material";
import styled from "@emotion/styled";
import {useNavigate, useParams} from "react-router-dom";
import {resetPassword} from "../../api/userApi";
import LabelText from "../../components/Common/LabelText";
import "./style.css";
import LoginLeftPart from "../../components/LoginLeftPart";
import {validateConfirmPassword, validatePassword,} from "../../helper/validation";
import TextFieldBox from "../../components/Common/TextFieldBox";

const MainContainer = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const StyledTypography = styled(Typography)(() => ({
  textAlign: "center",
  marginTop: "5rem",
}));

const LoginTypography = styled(Typography)(() => ({
  color: "#FFFFFF",
  fontWeight: 600,
  fontSize: "16px",
  textTransform: "initial",
}));

const ValidationTypography = styled(Typography)(() => ({
  color: "red",
  fontWeight: 600,
  fontSize: "16px",
}));

const ResetPassword = () => {
  const navigate = useNavigate();
  const [newPasswordValue, setNewPasswordValue] = useState();
  const [ , setConfirmPasswordValue] = useState();
  const [validationError, setValidationError] = useState("");
  const {authCode} = useParams();

  const handleFormSubmit = async () => {
    if (validationError === "") {
      const userData = {
        new_password: newPasswordValue,
        authorization_string: authCode
      }

      const res = await resetPassword(userData);
      console.log(res)
      if (res) {
        if (res.status !== 200) {
          if (res.data === undefined) {
            setValidationError(res.response?.data?.detail)
          } else {
            setValidationError(res.data?.detail)
          }
        } else {
          localStorage.removeItem("authToken");
          navigate("/")
        }
      }
    }
  }

  const handlePasswordInputChange = (e) => {
    setNewPasswordValue(e.target.value)
    setValidationError(validatePassword(e.target.value))
  }

  const handleConfirmPasswordInputChange = (e) => {
    setConfirmPasswordValue(e.target.value)
    setValidationError(validateConfirmPassword(newPasswordValue, e.target.value))
  }

  return (
    <MainContainer>
      <Grid
        style={{
          margin: "auto",
          width: "411px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="forgot_div">
          <div className="wrap_login_first_part">
            <Typography variant="mainText" mb={"11px"} component={"p"}>
              Password Reset
            </Typography>
            <Typography variant="mainSubText" component={"p"} mb={"11px"}>
              Please input and confirm your new password.
            </Typography>
            <Grid style={{minHeight: "50px"}}>
              <ValidationTypography>{validationError}</ValidationTypography>
            </Grid>
            <form>
              <Grid mb={"14px"} mt={"14px"}>
                <LabelText label={"New Password"} isRequired={true}/>
              </Grid>
              <TextFieldBox sx={{mb: 2, fontSize: "16px"}}
                key={"new_password_text_field"}
                required
                fullWidth
                type={"password"}
                placeholder={"New Password*"}
                name={"new_password"}
                handleChange={handlePasswordInputChange}
              />
              <Grid mb={"14px"} mt={"14px"}>
                <LabelText label={"Confirm Password"} isRequired={true}/>
              </Grid>
              <TextFieldBox sx={{mb: 2, fontSize: "16px"}}
                key={"confirm_password_text_field"}
                required
                fullWidth
                type={"password"}
                placeholder={"Confirm Password*"}
                name={"confirm_password"}
                handleChange={handleConfirmPasswordInputChange}
              />
              <Grid mt={"33px"}>
                <Button
                  variant="contained"
                  fullWidth
                  className="loginButton"
                  onClick={handleFormSubmit}
                >
                  <LoginTypography>Submit</LoginTypography>
                </Button>
              </Grid>
            </form>
          </div>
        </div>
      </Grid>
      <LoginLeftPart />
    </MainContainer>
  )
}

export default ResetPassword;