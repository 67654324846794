import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React, {useEffect, useState} from "react";
import LabelText from "../../../components/Common/LabelText";
import MenuItem from "@mui/material/MenuItem";
import {
  validateCompany,
  validateConfirmPassword,
  validateEmail,
  validateFirst,
  validateLast,
  validatePassword
} from "../../../helper/validation";
import {adminSignup, setTemporaryPassword} from "../../../api/adminApi";
import {blankUserData} from "./UserManagement";
import {useAuth} from "../../../components/AuthProvider";
import {updateUserDetail} from "../../../api/userApi";

const UserCreateOrEditModal = (props) => {
  const {
    mode = "closed",
    onClose = () => {},
    initialUserData = blankUserData
  } = props;

  const [firstName, setFirstName] = useState(initialUserData.first_name)
  const [lastName, setLastName] = useState(initialUserData.last_name)
  const [email, setEmail] = useState(initialUserData.email)
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [company, setCompany] = useState(initialUserData.company)
  const [role, setRole] = useState(initialUserData.role)
  const [validationError, setValidationError] = useState();

  const theme = useTheme();
  const {token} = useAuth();
  const matchesLg = useMediaQuery(theme.breakpoints.up('cdLg'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('cdMd'));

  useEffect(() => {
    setFirstName(initialUserData.first_name)
    setLastName(initialUserData.last_name)
    setEmail(initialUserData.email)
    setCompany(initialUserData.company)
    setRole(initialUserData.role)
  }, [initialUserData])

  const handlePasswordInputChange = (e) => {
    setNewPassword(e.target.value);
    setValidationError(validatePassword(e.target.value));
  }

  const handleConfirmPasswordInputChange = (e) => {
    setConfirmNewPassword(e.target.value);
    setValidationError(validateConfirmPassword(newPassword, e.target.value))
  }

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value)
    setValidationError(validateFirst(e.target.value))
  }

  const handleLastNameChange = (e) => {
    setLastName(e.target.value)
    setValidationError(validateLast(e.target.value))
  }

  const handleCompanyNameChange = (e) => {
    setCompany(e.target.value)
    setValidationError(validateCompany(e.target.value))
  }

  const handleRoleChange = (e) => {
    setRole(e.target.value)
    setValidationError("")
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    setValidationError(validateEmail(e.target.value))
  }

  const creationErrors = () => {
    return [
      validateFirst(firstName),
      validateLast(lastName),
      validateEmail(email),
      validatePassword(newPassword),
      validateConfirmPassword(newPassword, confirmNewPassword),
      validateCompany(company),
    ].filter((e) => e !== "");
  }

  const updateErrors = () => {
    return [
      validateFirst(firstName),
      validateLast(lastName),
      validateEmail(email),
      validateCompany(company),
    ].filter((e) => e !== "");
  }

  const passwordErrors = () => {
    return [validatePassword(newPassword), validateConfirmPassword(newPassword, confirmNewPassword)]
      .filter((e) => e !== "")
  }

  const handleSubmit = async () => {
    if (mode === "create") {
      const signupData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: newPassword,
        company: company,
        role: role
      }
      const res = await adminSignup(token, signupData);
      if (res) {
        if (res.status === 200) {
          cleanupAndExit()
        } else if (res.status === 409) {
          setValidationError("The server could not process the request." +
            " Ensure that the email is not already registered.")
        } else {
          setValidationError("The server could not process the request at this time." +
            " Please try again later.")
          console.log(res)
          setValidationError(res.data?.detail)
        }
      }
    } else if (mode === "update") {
      const payload = {
        first_name: firstName,
        last_name: lastName,
        // email: email,  // maybe in the future
        company: company,
        role_id: role
      };
      const res = await updateUserDetail(token, email, payload);
      if (res) {
        if (res.status === 200) {
          cleanupAndExit()
        } else {
          console.log(res)
        }
      }
    } else if (mode === "password") {
      const payload = {
        email: email,
        password: newPassword
      };
      const res = await setTemporaryPassword(token, payload);
      if (res) {
        if (res.status === 200) {
          cleanupAndExit()
        } else {
          console.log(res)
        }
      }
    }
  }

  const isSubmitDisabled = () => {
    if (mode === "create") {
      return creationErrors().length > 0
    } else if (mode === "update") {
      return updateErrors().length > 0
    } else if (mode === "password") {
      return passwordErrors().length > 0
    } else {
      return true
    }
  }

  const cleanupAndExit = () => {
    setFirstName(initialUserData.first_name)
    setLastName(initialUserData.last_name)
    setEmail(initialUserData.email)
    setNewPassword("")
    setConfirmNewPassword("")
    setCompany("")
    setRole(initialUserData.role)
    setValidationError("")
    onClose()
  }

  let gridTemplateColumns, gridTemplateAreas, gridWidth;

  if (matchesLg) {
    gridWidth = "lg"
    gridTemplateColumns = 'repeat(12, 1fr)'
    gridTemplateAreas =
     `"frnlbl frnlbl frntxt frntxt frntxt frntxt ltnlbl ltnlbl ltntxt ltntxt ltntxt ltntxt"
      "emllbl emllbl emltxt emltxt emltxt emltxt emltxt emltxt emltxt emltxt emltxt emltxt"
      "pwdlbl pwdlbl pwdtxt pwdtxt pwdtxt pwdtxt cpwlbl cpwlbl cpwtxt cpwtxt cpwtxt cpwtxt"
      "cnylbl cnylbl cnytxt cnytxt cnytxt cnytxt rollbl rollbl rolsel rolsel rolsel rolsel"
      "valerr valerr valerr valerr valerr valerr valerr valerr valerr valerr valerr valerr"
      ".      .      .      .      .      .      .      .      subbtn subbtn cnlbtn cnlbtn"`
   } else if (matchesMd) {
     gridWidth = "md"
     gridTemplateColumns = 'repeat(6, 1fr)'
     gridTemplateAreas =
     `"frnlbl frnlbl frntxt frntxt frntxt frntxt ltnlbl ltnlbl ltntxt ltntxt ltntxt ltntxt" 
      "emllbl emllbl emltxt emltxt emltxt emltxt emltxt emltxt emltxt emltxt emltxt emltxt" 
      "pwdlbl pwdlbl pwdtxt pwdtxt pwdtxt pwdtxt cpwlbl cpwlbl cpwtxt cpwtxt cpwtxt cpwtxt" 
      "cnylbl cnylbl cnytxt cnytxt cnytxt cnytxt rollbl rollbl rolsel rolsel rolsel rolsel" 
      "valerr valerr valerr valerr valerr valerr valerr valerr valerr valerr valerr valerr" 
      ".      .      .      .      .      .      subbtn subbtn subbtn cnlbtn cnlbtn cnlbtn"`
  } else {
    gridWidth = "sm"
    gridTemplateColumns = '1fr'
    gridTemplateAreas =
     `"frnlbl"
      "frntxt"
      "ltnlbl"
      "ltntxt"
      "emllbl"
      "emltxt"
      "pwdlbl"
      "pwdtxt"
      "cpwlbl"
      "cpwtxt"
      "cnylbl"
      "cnytxt"
      "rollbl"
      "rolsel"
      "valerr"
      "subbtn"
      "cnlbtn"`
  }


  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={mode !== "closed"}
      fullWidth={true}
      maxWidth={gridWidth}
      PaperProps={{ sx: { borderRadius: "8px" } }}
    >

      <DialogTitle id="simple-dialog-title">
        <Typography variant={"mainText"}>
          {mode === "create" ? "Create User" : "Update User"}
        </Typography>
      </DialogTitle>

      <Box
        component="form"
        noValidate
        autoComplete="off"
      >

        <Grid
          sx={{
            display: 'grid',
            gridTemplateColumns: gridTemplateColumns,
            gap: 2,
            gridTemplateRows: 'auto',
            gridTemplateAreas: gridTemplateAreas,
            alignItems: "center",
            p: "25px"
          }}
        >

          <Grid gridArea="frnlbl">
            <Grid mb={"14px"}>
              <LabelText label={"First name"} isRequired={true}/>
            </Grid>
          </Grid>
          <Grid gridArea="frntxt">
            <TextField sx={{mb: 2, fontSize: "16px"}}
              key={"first_name_text_field"}
              required
              fullWidth
              disabled={mode === "password"}
              placeholder={"First name"}
              name={"first_name"}
              value={firstName}
              onChange={handleFirstNameChange}
            />
          </Grid>

          <Grid gridArea="ltnlbl">
            <Grid mb={"14px"}>
              <LabelText label={"Last name"} isRequired={true}/>
            </Grid>
          </Grid>
          <Grid gridArea="ltntxt">
            <TextField sx={{mb: 2, fontSize: "16px"}}
              key={"last_name_text_field"}
              required
              fullWidth
              disabled={mode === "password"}
              placeholder={"Last name"}
              name={"last_name"}
              value={lastName}
              onChange={handleLastNameChange}
            />
          </Grid>

          <Grid gridArea="emllbl">
            <Grid mb={"14px"}>
              <LabelText label={"Email"} isRequired={true}/>
            </Grid>
          </Grid>
          <Grid gridArea="emltxt">
            <TextField sx={{mb: 2, fontSize: "16px"}}
              key={"email_text_field"}
              required
              fullWidth
              disabled={mode !== "create"}
              placeholder={"Email"}
              name={"email"}
              value={email}
              onChange={handleEmailChange}
            />
          </Grid>

          <Grid gridArea="pwdlbl">
            <Grid mb={"14px"}>
              <LabelText label={"Password"} isRequired={true}/>
            </Grid>
          </Grid>
          <Grid gridArea="pwdtxt">
            <TextField sx={{mb: 2, fontSize: "16px"}}
              key={"password_text_field"}
              required
              type={"password"}
              fullWidth
              placeholder={"Password"}
              name={"password"}
              disabled={mode === "update"}
              onChange={handlePasswordInputChange}
            />
          </Grid>

          <Grid gridArea="cpwlbl">
            <Grid mb={"14px"}>
              <LabelText label={"Confirm password"} isRequired={true}/>
            </Grid>
          </Grid>
          <Grid gridArea="cpwtxt">
            <TextField sx={{mb: 2, fontSize: "16px"}}
              key={"confirm_password_text_field"}
              required
              type={"password"}
              fullWidth
              placeholder={"Confirm Password"}
              name={"confirm_password"}
              disabled={mode === "update"}
              onChange={handleConfirmPasswordInputChange}
            />
          </Grid>

          <Grid gridArea="cnylbl">
            <Grid mb={"14px"}>
              <LabelText label={"Company"} isRequired={true}/>
            </Grid>
          </Grid>
          <Grid gridArea="cnytxt">
            <TextField sx={{mb: 2, fontSize: "16px"}}
              key={"company_text_field"}
              required
              fullWidth
              disabled={mode === "password"}
              placeholder={"Company"}
              name={"company"}
              value={company}
              onChange={handleCompanyNameChange}
            />
          </Grid>

          <Grid gridArea="rollbl">
            <Grid mb={"14px"}>
              <LabelText label={"Role"}/>
            </Grid>
          </Grid>
          <Grid gridArea="rolsel">
            <Select
              sx={{mb: 2, fontSize: "16px"}}
              labelId="role-select-label"
              id="role-select"
              value={role}
              disabled={mode === "password"}
              fullWidth
              onChange={handleRoleChange}
             variant={"outlined"}
            >
              <MenuItem value={2}>Regular User</MenuItem>
              <MenuItem value={1}>Superuser/Administrator</MenuItem>
            </Select>
          </Grid>

          <Grid style={{minHeight: "50px"}} gridArea={"valerr"}>
            <Typography variant={"formValidation"}>{validationError}</Typography>
          </Grid>

          <Grid gridArea="subbtn">
            <Button
              fullWidth
              onClick={handleSubmit}
              disabled={isSubmitDisabled()}
              variant="contained"
              sx={{borderRadius: "6px", paddingBlock: "22px"}}
            >
              <Typography variant={"buttonFace"}>{mode === 'create' ? "Create" : "Update"}</Typography>
            </Button>
          </Grid>

          <Grid gridArea="cnlbtn">
           <Button
             fullWidth
             onClick={cleanupAndExit}
             variant="contained"
             sx={{borderRadius: "6px", paddingBlock: "22px"}}
           >
             <Typography variant={"buttonFace"}>Cancel</Typography>
           </Button>
          </Grid>

        </Grid>
      </Box>
    </Dialog>
  )
}

export default UserCreateOrEditModal