import styled from "@emotion/styled";
import {Box, Card, IconButton, Tooltip, Typography} from "@mui/material";
import PropTypes from "prop-types";
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import React, {useEffect, useState} from "react";
import {getCompanyDetailNew} from "../../api/companyApi";
import {useAuth} from "../AuthProvider";
import {labelForLocation} from "../../helper/address";

const TooltipTypography = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 16,
}));

const CardBoundary = styled(Card)(() => ({
  border: "1px solid rgba(88, 89, 91, 0.2)",
  borderRadius: "8px",
  width: "500px",
  padding: "15px",
  transition: "transform 0.15s ease-in-out",
}));

const CompanyListCard = (props) => {
  const { id } = props
  const { token } = useAuth();
  const [companyData, setCompanyData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getCompanyDetailNew(token, id)
      setCompanyData(response.data);
    };
    fetchData();
  }, [])

  const handleCompanyDeepDiveClick = () => {
    window.open(`/company/${companyData?.stagedId}`,'_new', 'rel=noopener noreferrer')
  }

  const nameToUse = companyData?.primaryName ? companyData.primaryName : "(Unknown Name)"
  const subtitle = (companyData?.primaryLocation) ? labelForLocation(companyData.primaryLocation) : ""

  return (
    <CardBoundary variant="outlined" sx={{mb: "10px"}}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr) 150px",
          gridTemplateAreas: `"name name name name button"
          "id id id id button"`
        }}
      >
        <Box sx={{gridArea: "name"}}>
          <Typography variant={"h6"}>{nameToUse}</Typography>
        </Box>
        <Box sx={{gridArea: "id"}}>
          <Typography variant={"caption"}>{subtitle}</Typography>
        </Box>
        <Box sx={{gridArea: "button", display: "flex", justifyContent: "right", alignItems: "center"}}>
          <Tooltip
            title={<TooltipTypography>Details</TooltipTypography>}
            placement={"top"}
            arrow
          >
            <IconButton onClick={handleCompanyDeepDiveClick}>
              <ReadMoreIcon/>
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </CardBoundary>

)}

CompanyListCard.propTypes = ({
  id: PropTypes.string.isRequired
})

export default CompanyListCard