import styled from "@emotion/styled";
import {Button, Dialog, DialogTitle, Grid, ListItemText, OutlinedInput, Select, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import LabelText from "./Common/LabelText";
import TextFieldBox from "./Common/TextFieldBox";
import {useAuth} from "./AuthProvider";
import {getCompanyDetail} from "../api/companyApi";
import {addCompanyToList, getTargetLists} from "../api/list";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";

const StyledGrid = styled(Grid)(() => ({
  minWidth: "450px",
  minHeight: "250px",
  paddingLeft: "30px",
  paddingRight: "30px",
  paddingBottom: "30px",
}));

function AddToListModal(props) {
  const {
    onClose,
    open,
    stagedId,
    onAdded = () => {}
  } = props;

  const [companyData, setCompanyData] = useState(null);
  const [usersLists, setUsersLists] = useState([]);
  const [selectedLists, setSelectedLists] = useState([]);
  const [validationError, setValidationError] = useState("");
  const [submissionCount] = useState(0);

  const {token} = useAuth();

  useEffect(() => {
    // Load data when opening the modal
    if (open) {
      setValidationError("")
      getCompanyDetail(token, stagedId).then( (res) => {
        if (res) {
          if (res?.status === 200) {
            setCompanyData(res?.data);
          } else {

          }
        }
      })
    } else {
      // Do nothing; We don't want to load data when closing the dialog
    }
  }, [open, stagedId]);

  useEffect(() => {
    // Load data when opening the modal
    if (open) {
      setValidationError("")
      getTargetLists(token).then( (res) => {
        if (res) {
          if (res?.status === 200) {
            setUsersLists(res?.data);
          } else {

          }
        }
      })
    } else {
      // Do nothing; We don't want to load data when closing the dialog
    }
  }, [open, stagedId]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLists(value);
  };

  const describeSelection = (selected) => {
    if (selected?.length === 0) {
      return "Make selection"
    } else if (selected?.length === 1) {
      return usersLists?.filter((x) => x._id === selected[0])[0]?.name
    } else {
      return `${selected.length} lists selected`
    }
  }

  const handleUpdateLists =  async (e) => {
    e.preventDefault()

    const promises = selectedLists.map((sl) => {
      addCompanyToList(token, sl, stagedId, "")
    })

    Promise.all(promises)
      .then(() => {
        onClose()
      }).then(() => {
        setSelectedLists([]);
      }).then(
        onAdded()
      )
  }

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{ sx: { borderRadius: "8px" } }}>

      <DialogTitle id="simple-dialog-title">
        <Typography variant={"mainText"}>
          Add Company to List
        </Typography>
      </DialogTitle>

      <StyledGrid container spacing={2}>

        <Grid item xs={12} style={{minHeight: "50px"}}>
          <Typography variant={"formValidation"}>{validationError}</Typography>
        </Grid>

        <Grid item md={12}>
          <Grid mb={"14px"}>
            <LabelText label={"Company ID"}/>
          </Grid>
          <TextFieldBox sx={{mb: 2, fontSize: "16px"}}
            key={"company_id_text_field"}
            fullWidth
            disabled={true}
            placeholder={"Company ID"}
            name={"staged_id"}
            value={stagedId}
            handleChange={() => {}}
          />
        </Grid>

        <Grid item md={12}>
          <Grid mb={"14px"}>
            <LabelText label={"Company Name"}/>
          </Grid>
          <TextFieldBox sx={{mb: 2, fontSize: "16px"}}
            key={"company_name_text_field"}
            fullWidth
            disabled={true}
            placeholder={"Company Name"}
            name={"primaryName"}
            value={companyData?.primaryName}
            handleChange={() => {}}
          />
        </Grid>

        <Grid item md={12}>
          <Grid mb={"14px"}>
            <LabelText label={"Select List(s)"} isRequired={true}/>
          </Grid>
          <Select
            sx={{mb: 2, fontSize: "16px"}}
            labelId="list-selection-checkbox-label"
            id="list-selection-checkbox"
            key={`list-selection-checkbox-submission-${submissionCount}`}
            multiple
            fullWidth={true}
            value={selectedLists}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={describeSelection}
            // MenuProps={MenuProps}
            variant={"standard"}
          >
            {usersLists.map((list) => (
              <MenuItem key={list?._id} value={list?._id}>
                <Checkbox checked={selectedLists.indexOf(list?._id) > -1} />
                <ListItemText primary={list?.name} />
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12} md={6} mt={"33px"}>
          <Grid>
            <Button
              variant="contained"
              sx={{paddingBlock: "22px"}}
              fullWidth
              disabled={selectedLists.length === 0}
              onClick={handleUpdateLists}
            >
              <Typography variant={"buttonFace"}>Update Lists</Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} mt={"33px"}>
          <Grid>
            <Button
              variant="contained"
              sx={{paddingBlock: "22px"}}
              fullWidth
              onClick={onClose}
            >
              <Typography variant={"buttonFace"}>Cancel</Typography>
            </Button>
          </Grid>
        </Grid>

      </StyledGrid>
    </Dialog>
  );
}

export default AddToListModal;