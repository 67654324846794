import TargetListInventory from "./TargetListInventory";
import React, {useState} from "react";
import {Box, Grid} from "@mui/material";
import {TargetListDetail} from "./TargetListDetail";
import MainLayout from "../../components/layouts/MainLayout";
import styled from "@emotion/styled";
import SubHeader from "../../components/layouts/SubHeader";

const Wrapper = styled(Grid)(() => ({
  padding: "102px 0px 0px 0px",
  //  background: "#F2F3F4",
  "@media (max-width: 900px)": {
    // Styles for screens with a maximum width of 768px
    padding: "66px 0px 0px 0px",
  },
}));

export default function TargetLists() {
  const [selectedListId, setSelectedListId] = useState(null);
  const [listMemberChangeCounter, setListMemberChangeCounter] = useState(0);

  const handleListSelection = (selectedRows) => {
    setSelectedListId(selectedRows[0])
  }

  const handleMemberChange = () => {
    setListMemberChangeCounter(listMemberChangeCounter + 1)
  }

  return (
    <MainLayout>
      <Wrapper>
        <SubHeader/>
        <Box sx={{marginY: "10px", marginX: "75px"}}>
          <Box sx={{marginBottom: "36px"}}>
            <TargetListInventory
              onSelectionChange={handleListSelection}
              membershipChangeCount={listMemberChangeCounter}
            />
          </Box>
          <Box>
          <TargetListDetail
            selectedListId={selectedListId}
            onMemberChange={handleMemberChange}
          />
          </Box>
        </Box>
      </Wrapper>
    </MainLayout>
  )
}