
import  'jimp/browser/lib/jimp';
const { Jimp } = window;  // See https://github.com/jimp-dev/jimp/issues/1194

export const addBackground = async (src, w_out, h_out) => {
  let imgOut = null


  await Jimp.read(src)
    .then(image => {
      let r = (x) => Math.round(x)
      let h = image.bitmap.height
      let w = image.bitmap.width
      const points = [
        [0, 0],
        [w, 0],
        [w, h],
        [0, h],
        [r(0.25 * w), 0],
        [r(0.50 * w), 0],
        [r(0.75 * w), 0],
        [r(0.25 * w), h],
        [r(0.50 * w), h],
        [r(0.75 * w), h],
        [0, r(0.25 * h)],
        [0, r(0.50 * h)],
        [0, r(0.75 * h)],
        [w, r(0.25 * h)],
        [w, r(0.50 * h)],
        [w, r(0.75 * h)]
      ];

      const colors = points.map(([x, y]) => image.getPixelColor(x, y));

      const colorMap = colors.reduce(
        (acc, c) => acc.set(c, (acc.get(c) || 0) + 1), new Map()
      );

      const [bgColor] = [...colorMap.entries()].reduce(
        (sofar, c) => c[1] > sofar[1] ? c : sofar);

      let bg = new Jimp(w_out, h_out, bgColor);

      const x_offset = r((w_out - w) / 2)
      const y_offset = r((h_out - h) / 2)

      imgOut = bg.blit(image, x_offset, y_offset)
    })

  let imgOutString = null
  imgOut.getBase64(Jimp.MIME_PNG, (err, b64) => {
    imgOutString = b64
  })

  if (imgOutString === null) {
    return false;
  } else {
    return imgOutString
  }
}