import styled from "@emotion/styled";
import {Card, Typography} from "@mui/material";

styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 16,
}));

const CardBoundary = styled(Card)(() => ({
  border: "1px solid rgba(88, 89, 91, 0.2)",
  borderRadius: "8px",
  width: "500px",
  // maxHeight: "300px",
  // overflowY: "scroll",
  padding: "15px",
  transition: "transform 0.15s ease-in-out",
  "&:hover": {
    // transform: "scale3d(1.01, 1.01, 1)",
    // background: "#fafafa"
  },
}));

export const NarrativeCard = (props) => {
  const {description} = props

  return (
    <CardBoundary variant="outlined" sx={{mb: "10px"}}>
      {description}
    </CardBoundary>
  )
}