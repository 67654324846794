import {Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography} from "@mui/material";
import {Map, Marker} from '@vis.gl/react-google-maps';
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MapIcon from '@mui/icons-material/Map';
import {useTheme} from "@emotion/react";
import {AddressPropTypes} from "../../schema/address";
import twoLineAddressFromProps from "../../helper/address"
import styled from "@emotion/styled";

const CaptionLikeDetails = styled(Typography)(() => ({
  display: "block",
  gutterBottom: false
}));

export const LocationCard = (props) => {

  const {mb, expanded} = props

  useTheme();

  const [line1, line2] = twoLineAddressFromProps(props)

  const formattedLat = props?.location?.lat ? Math.round((props.location.lat + Number.EPSILON) * 100000) / 100000 : ""
  const formattedLon = props?.location?.long ? Math.round((props.location.long + Number.EPSILON) * 100000) / 100000 : ""


  return (
    <Box sx={{border: "1px solid rgba(88, 89, 91, 0.2)", borderRadius: "8px", mb: mb}}
    >
      <Accordion defaultExpanded={expanded} sx={{width: "500px"}} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{borderRadius: "24px"}}
        >
          <Box sx={{width: "100%", display: "grid", gridTemplateColumns: "1fr 26px"}}>
            <Box sx={{flexDirection: "columns"}}>
              <Typography variant={"body1"}>{line1}</Typography>
              <Typography variant={"body1"}>{line2}</Typography>
              <Box sx={{flexDirection: "rows", pt: "10px"}}>
                {"msa" in props.location &&
                  <CaptionLikeDetails variant="caption">
                    <b>MSA:</b> {props?.location?.msa}
                  </CaptionLikeDetails>
                }
                {"county" in props.location &&
                  <CaptionLikeDetails variant="caption">
                    <b>County:</b> {props?.location?.county}
                  </CaptionLikeDetails>
                }
                {("lat" in props?.location) && ("long" in props?.location) &&
                  <CaptionLikeDetails variant="caption">
                    <b>Geo:</b> {formattedLat}, {formattedLon}
                  </CaptionLikeDetails>
                }
              </Box>
            </Box>
            <Box sx={{justifyContent: 'flex-end', alignContent: "center"}}>
              <MapIcon sx={{color: "#00000099"}}/>
            </Box>

          </Box>

        </AccordionSummary>
        <AccordionDetails sx={{margin: "0px", padding: "0px"}}>
          <Divider sx={{marginTop: "0px", marginBottom: "0px"}}/>
          <Box
            sx={{
              height: "175px",
              width: "500px",
              filter: "hue-rotate(30deg)"
            }}
          >
           <Map
             sx={{padding: "0px"}}
              defaultZoom={4}
              defaultCenter={{ lat: props.location.lat, lng: props.location.long }}
              disableDefaultUI={true}
              styles={[
                {
                  "featureType": "poi",
                  "elementType": "all",
                  "stylers": [
                    { "visibility": "off" }
                  ]
                }
              ]}
           >
             <Marker position={{ lat: props.location.lat, lng: props.location.long }} />
           </Map>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
    )
}


LocationCard.propTypes = {
  location: AddressPropTypes.isRequired
}