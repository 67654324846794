import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PeopleIcon from "@mui/icons-material/People";
import ApartmentIcon from "@mui/icons-material/Apartment";
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import Typography from "@mui/material/Typography";
import EventTable from "./EventTable";
import {UserManagement} from "./user/UserManagement";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function AdminTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab
            label={
              <Typography variant={"h5"} sx={{pl: "4px"}}>
                Event Log
              </Typography>
            }
            icon={<DynamicFormIcon/>}
            iconPosition="start"
            sx={{
              textTransform: "none"
            }}
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Typography variant={"h5"} sx={{pl: "4px"}}>
                User Management
              </Typography>
            }
            icon={<PeopleIcon />}
            iconPosition="start"
            sx={{
              textTransform: "none"
            }}
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Typography variant={"h5"} sx={{pl: "4px"}}>
                Company Management
              </Typography>
            }
            icon={<ApartmentIcon />}
            iconPosition="start"
            disabled
            sx={{
              textTransform: "none"
            }}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <EventTable/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <UserManagement/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Company Management
      </CustomTabPanel>
    </Box>
  );
}