import {Card, Divider, List, ListSubheader, Typography} from "@mui/material";
import React from "react";
import NewsStory from "./NewsStory";


export default function NewsAndNotes({stories}) {
  return (
    <Card variant="outlined" sx={{maxWidth: "1/3"}}>
      <ListSubheader sx={{pt: "25px", pb: "25px"}}>
        <Typography style={{ fontWeight: 700, fontSize: 22, color: "#4A2CF5" }} variant="h6">
          News & Notes
        </Typography>
      </ListSubheader>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {stories?.map((story, index) => {
          return(
            <>
              {(index !== 0) ? <Divider variant="inset"/> : <></>}
              <NewsStory title={story.title} date={story.date} body_text={story.body_text}/>
            </>
          );
        })}
      </List>
    </Card>
  )
}