import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {Button, Grid, Snackbar, TextField, Typography} from "@mui/material";
import LoginLeftPart from "../../components/LoginLeftPart";
import styled from "@emotion/styled";
import {updatePassword} from "../../api/userApi";
import {validateConfirmPassword, validatePassword,} from "../../helper/validation";
import {ErrorMessage} from "../SignupPage";
import "./style.css";
import {useAuth} from "../../components/AuthProvider";

styled(Grid)(() => ({
  display: "flex",
  gap: "20px",
  marginBottom: "40px",
}));

const MainContainer = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "space-between"
}));

const StyledTextField = styled(TextField)(() => ({
  border: "1px solid  rgb(88, 89, 91,0.7)",
  borderRadius: "6px",
  width: "100%"
}));

const LoginTypography = styled(Typography)(() => ({
  color: "#FFFFFF",
  fontWeight: 600,
  fontSize: "16px",
  textTransform: "initial",
}));

const CancelBtn = styled(Button)(() => ({
  backgroundColor: "#red",
  fontWeight: 600,
  fontSize: "16px",
  textTransform: "initial",
}));

styled(Grid)`
  @media (max-width: 1200px) {
    padding:84px 15px !important;
  }`;


export default function UpdatePasswordPage() {

  const { token } = useAuth();
  const navigate = useNavigate();

  const [formErrors, setFormErrors] = useState({});
  const [isSnackbarOpen, setIsSnackbarOpen]  = useState(false);
  const [passwordChangeResult, setPasswordChangeResult]  = useState("");
  const [formData, setFormData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  useEffect( () => {
      if (passwordChangeResult !== "") {
        setIsSnackbarOpen(true)
      }
    }, [passwordChangeResult]
  )

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackbarOpen(false);
    setPasswordChangeResult("")
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const errors = {
      new_password: name === "new_password" && validatePassword(value),
      confirm_password:
        name === "confirm_password" &&
        validateConfirmPassword(formData.new_password, value),
    };
    setFormErrors({ ...formErrors, ...errors });
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userdata = {
      old_password: formData?.old_password,
      new_password: formData?.new_password
    };
    const { new_password, confirm_password } = formData;
    const errors = {
      new_password: validatePassword(new_password),
      confirm_password: validateConfirmPassword(new_password, confirm_password),
    };
    setFormErrors(errors);
    const res = await updatePassword(token, userdata);
    if (res) {
      if (res.status === 400) {
        console.log(res.data.detail)
        setPasswordChangeResult(res.data.detail)
      }
      if (res.status === 200) {
        setPasswordChangeResult("Password successfully changed.")
        navigate("/landing")
      }
    }
  };

  const handleCancel = () => {
    navigate("/landing")
  }

  return (
    <MainContainer >
      <Grid style={{
        margin: "auto",
        display: "flex",
        maxWidth: "411px",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <div className="forgot_div">
        <div className="wrap_login_first_part">
          <Typography variant="mainText" mb={"11px"} component={"p"}>
            Change Password
          </Typography>
          <Typography variant="mainSubText" component={"p"} mb={"51px"}>
            Please enter your current and new passwords.
          </Typography>
          <Grid>
            <form onSubmit={handleSubmit}>
              <Typography variant="h6" component={"p"} mb={"14px"} >
                Old Password
              </Typography>
              <StyledTextField
                required
                type="password"
                placeholder="Current Password"
                name="old_password"
                value={formData.old_password}
                onChange={handleChange}
              />
              <Typography variant="h6" component={"p"} mb={"14px"} mt={"23px"}>
                New Password
              </Typography>
              <StyledTextField
                required
                type="password"
                placeholder="New Password"
                name="new_password"
                value={formData.new_password}
                onChange={handleChange}
              />
              <Grid>
                <ErrorMessage>{formErrors.new_password}</ErrorMessage>
              </Grid>

              <Typography variant="h6" component={"p"} mb={"14px"} mt={"23px"}>
                Confirm New Password
              </Typography>
              <StyledTextField
                required
                type="password"
                placeholder="Confirm New Password"
                name="confirm_password"
                value={formData.confirm_password}
                onChange={handleChange}
              />
              <Grid>
                <ErrorMessage>{formErrors.confirm_password}</ErrorMessage>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",

                }}
              >
                <Grid mt={"82px"} width={"150px"}>
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    className="loginButton"
                  >
                    <LoginTypography>Update</LoginTypography>
                  </Button>
                </Grid>
                <Grid mt={"82px"} width={"150px"}> 
                  <CancelBtn
                    variant="contained"
                    // type="submit"
                    fullWidth
                    className="loginButton"
                    onClick={handleCancel}
                  >
                    <LoginTypography>Cancel</LoginTypography>
                  </CancelBtn>
                </Grid>
              </Grid>
            </form>
              <Snackbar open={isSnackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                {passwordChangeResult}
              </Snackbar>
          </Grid>
        </div>
        </div>
      </Grid>
      <LoginLeftPart />
    </MainContainer>
  );
}
