import Box from "@mui/material/Box";
import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography} from "@mui/material";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import * as React from "react";
import {useEffect, useState} from "react";
import {addCompanyToList, getTargetList, removeCompanyFromList} from "../../api/list";
import {doesCompanyIdExist, getCompanyDetail, registerCompanyInquiry} from "../../api/companyApi";
import {useAuth} from "../../components/AuthProvider";
import {labelForLocation} from "../../helper/address";
import {DollarFormatter, EmployeeFormatter} from "../../helper/formatting";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
import {Add} from "@mui/icons-material";
import CompanyModal from "../../components/CompanyModal/CompanyModal";
import defaultImageUrl from "../../assets/defaultImage.svg";
import {TargetListDetailToolbar} from "./TargetListDetailToolbar";
import 'jimp/browser/lib/jimp';
import {addBackground} from "../../helper/image_processing";

const { Jimp } = window;  // See https://github.com/jimp-dev/jimp/issues/1194



export const TargetListDetail = (props) => {
  const {
    selectedListId = null,
    onMemberChange = () => {},
    headerText = "List Contents"
  } = props

  const [reloadListCount, setReloadListCount] = useState(0);
  const [, setSelectedListData] = useState(null)
  const [, setSelectedCompanyId] = useState(null);
  const [selectedCompanyData, setSelectedCompanyData] = useState(null);
  const [possibleListAddition, setPossibleListAddition] = useState("")
  const [isCompanyLookupButtonDisabled, setIsCompanyLookupButtonDisabled] = useState(true);
  const [companyModalOpen, setCompanyModalOpen] = useState(false)
  const [imageData, setImageData] = useState(defaultImageUrl);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false)
  const {token} = useAuth();

  useEffect(() => {
    setLoading(true)
    if (selectedListId) {
      getTargetList(token, selectedListId)
        .then((response) => {
          setSelectedListData(response?.data)
          return response?.data;
        })
        .then(listData => {
          Promise.all(getCompaniesOnList(listData))
            .then((encodedCompanies) => {
              setRows(encodedCompanies)
            })
        })
    }
    setLoading(false)
  }, [selectedListId, reloadListCount])

  useEffect(() => {
    async function processCompanyLookupButtonState() {
      if (possibleListAddition && possibleListAddition.length > 0) {
        doesCompanyIdExist(token, possibleListAddition).then((idExistsResponse) => {
            if (idExistsResponse.data === true) {
              setIsCompanyLookupButtonDisabled(false)
            } else if (idExistsResponse.data === false) {
              setIsCompanyLookupButtonDisabled(true)
            } else {
              setIsCompanyLookupButtonDisabled(true)
            }
          }
        )
      } else {
        setIsCompanyLookupButtonDisabled(true)
      }
    }
    processCompanyLookupButtonState();
  }, [possibleListAddition]);

  useEffect(() => {
    if (companyModalOpen === false) {
      setImageData(defaultImageUrl);
    }
  }, [companyModalOpen])

  useEffect(() => {
      const getCompanyLogo = async (uri) => {
        if (uri) {
        // We don't want to use zoominfo image processing...
        const unprocessed_uri = uri.replace("w_70,h_70,c_fit", "")

        await Jimp.read(unprocessed_uri)
          .then(image => image
            .autocrop()
            .scaleToFit(70, 70))
          .then(processed =>
            processed.getBase64Async(Jimp.MIME_PNG))
          .then(imageString => {
            addBackground(imageString, 74, 74)
            .then(withBg => {
              if (withBg) {
                setImageData(withBg)
              } else {
                setImageData(imageString)
              }
            })
          })
          .catch((err) => {
            console.log(err)
          });
        }
      }
      getCompanyLogo(selectedCompanyData?.primaryLogo)
    }, [selectedCompanyData]);

  const gridEncode = (companyId) => {
    return getCompanyDetail(token, companyId)
      .then((response) => {
        return (response.data)
      })
      .then((companyData) => {
        return (
          {
            id: companyData?.stagedId,
            companyName: companyData?.primaryName,
            location: labelForLocation(companyData?.primaryLocation),
            estRevenue: companyData?.revenue?.pointEstimate,
            estHeadcount: companyData?.harmonizedHeadcount?.pointEstimate,
            lbRevenue: companyData?.revenue?.lowerBound,
            ubRevenue: companyData?.revenue?.upperBound,
            lbHeadcount: companyData?.harmonizedHeadcount?.lowerBound,
            ubHeadcount: companyData?.harmonizedHeadcount?.upperBound,
            primaryWebsite: companyData?.primaryWebsite,
            primaryTelephone: companyData?.primaryTelephone,
          }
        )}
      )
  }

  const getCompaniesOnList = (listDefinition) => {
    let gridDataSet
    if (Array.isArray(listDefinition?.companies)) {
      gridDataSet = listDefinition.companies.map(gridEncode)
    } else {
      gridDataSet = []
    }
    return gridDataSet
  }

  const handleDeleteClick = (id) => () => {
    removeCompanyFromList(token, selectedListId, id)
      .then(setReloadListCount(reloadListCount + 1))
      .then(() => onMemberChange())
  }

  function handleAddToListClick() {
    addCompanyToList(token, selectedListId, possibleListAddition, "")
      .then(() => onMemberChange())

    const delay = ms => new Promise((resolve) => setTimeout(resolve, ms));
    delay(500).then(() => {setReloadListCount(reloadListCount + 1)});
  }

  const handleModalExpandClick = (id) => () => {
    getCompanyDetail(token, id)
      .then(response => {
        setSelectedCompanyData(response?.data);
      })
      .then(registerCompanyInquiry(token, id))
      .then(setCompanyModalOpen(true))
  }

  const handleCompanyDeepDiveClick = (id) => () => {
    window.open(`/company/${id}`,'_new', 'rel=noopener noreferrer')
  }


  const columns = [
    { field: 'id', headerName: 'ID', width: 150, visible: false },
    {
      field: 'moreInfo',
      type: 'actions',
      headerName: 'More Info',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<AspectRatioOutlinedIcon />}
            label="Detail Popup"
            onClick={handleModalExpandClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<ReadMoreIcon />}
            label="Company Info Page"
            onClick={handleCompanyDeepDiveClick(id)}
            color="inherit"
          />
        ]
      }
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      flex: 2.5,
      minWidth: 150,
      editable: false,
    },

    {
      field: 'location',
      headerName: 'Location',
      flex: 2,
      minWidth: 150,
      editable: false,
    },
    {
      field: 'estRevenue',
      headerName: 'Est. Revenue',
      type: 'number',
      flex: 1,
      maxWidth: 150,
      minWidth: 50,
      editable: false,
      valueFormatter: (value) => DollarFormatter.format(value.value)
    },
    {
      field: 'estHeadcount',
      headerName: 'Est. Headcount',
      type: 'number',
      flex: 1,
      minWidth: 150,
      editable: false,
      valueFormatter: (value) => EmployeeFormatter.format(value.value)
    },
    {
      field: 'lbRevenue',
      headerName: 'Revenue (Low)',
      type: 'number',
      flex: 1,
      minWidth: 150,
      editable: false,
      valueFormatter: (value) => DollarFormatter.format(value.value)
    },
    {
      field: 'ubRevenue',
      headerName: 'Revenue (High)',
      type: 'number',
      flex: 1,
      minWidth: 150,
      editable: false,
      valueFormatter: (value) => DollarFormatter.format(value.value)
    },
    {
      field: 'lbHeadcount',
      headerName: 'Headcount (Low)',
      type: 'number',
      flex: 1,
      minWidth: 150,
      editable: false,
      valueFormatter: (value) => EmployeeFormatter.format(value.value)
    },
    {
      field: 'ubHeadcount',
      headerName: 'Headcount (High)',
      type: 'number',
      flex: 1,
      minWidth: 150,
      editable: false,
      valueFormatter: (value) => EmployeeFormatter.format(value.value)
    },
    {
      field: 'primaryWebsite',
      headerName: 'Website',
      type: 'string',
      flex: 2,
      minWidth: 150,
      editable: false,
    },
    {
      field: 'primaryTelephone',
      headerName: 'Telephone',
      type: 'string',
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<RemoveCircleOutlineIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />
        ]
      }
    }
  ]

  return (
  <>
    <CompanyModal
      open={companyModalOpen}
      setOpen={setCompanyModalOpen}
      companyDetail={selectedCompanyData}
      imageData={imageData}
    />
    <Box sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" , alignItems: 'baseline'}}>
    <Typography variant={"h4"}>{headerText}</Typography>
      <Box sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: 'flex-end',
        alignItems: 'baseline',
      }}>
        <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
          <InputLabel size="small">Add by Id</InputLabel>
          {/* "key" property, reference: 54d6dd298cef4a29 */}
          <OutlinedInput
            key={`add-to-list-reloaded-number-${reloadListCount}`}
            id="new-list-name-input"
            label={"Add by Id"}
            size="small"
            onChange={(event) => setPossibleListAddition(event.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="add new list by name"
                  onClick={() => {handleAddToListClick()}}
                  disabled={isCompanyLookupButtonDisabled}
                  edge="end"
                >
                  {<Add/>}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
    </Box>
    <Box sx={{height: "495px", width: '100%'}}>
      <DataGrid
        key={`data-grid-reloaded-number-${reloadListCount}`}
        rowHeight={35}
        hideFooterSelectedRowCount={true}
        scrollbarSize={0}
        rows={rows}
        loading={loading}
        slotProps={{loadingOverlay: {variant: 'skeleton'} }}
        onRowSelectionModelChange={
          (selectedRows) => {
            setSelectedCompanyId(selectedRows?.[0]?.id)
          }
        }
        columns={columns}
        slots={{
          toolbar: TargetListDetailToolbar,
        }}
        sx={{'& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {display: 'none' }}}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              lbRevenue: false,
              ubRevenue: false,
              lbHeadcount: false,
              ubHeadcount: false
            }
          },
          pagination: {
            paginationModel: {
              pageSize: 10,
            }
          }
        }}
      />
    </Box>
  </>
  )
}