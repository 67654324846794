export default function twoLineAddressFromProps(props) {
  const {location} = props
  if (typeof location === 'undefined') {
    return ([undefined, undefined])
  } else {
    if (typeof props?.streetName === 'undefined') {
      const line1 = `${location.fullStreet}`
      const line2 = `${location.city}, ${location.region1} ${location.postalCode}`
      return ([line1, line2])
    } else {
      return (location.fullAddress.split(/,(.*)/s))
    }
  }
}

const stateAbbreviations = {
  "alabama": "AL",
  "alaska": "AK",
  "arizona": "AZ",
  "arkansas": "AR",
  "california": "CA",
  "colorado": "CO",
  "connecticut": "CT",
  "delaware": "DE",
  "district of columbia": "DC",
  "florida": "FL",
  "georgia": "GA",
  "guam": "GU",
  "hawaii": "HI",
  "idaho": "ID",
  "illinois": "IL",
  "indiana": "IN",
  "iowa": "IA",
  "kansas": "KS",
  "kentucky": "KY",
  "louisiana": "LA",
  "maine": "ME",
  "maryland": "MD",
  "massachusetts": "MA",
  "michigan": "MI",
  "minnesota": "MN",
  "mississippi": "MS",
  "missouri": "MO",
  "montana": "MT",
  "nebraska": "NE",
  "nevada": "NV",
  "new hampshire": "NH",
  "new jersey": "NJ",
  "new mexico": "NM",
  "new york": "NY",
  "north carolina": "NC",
  "north dakota": "ND",
  "ohio": "OH",
  "oklahoma": "OK",
  "oregon": "OR",
  "pennsylvania": "PA",
  "puerto rico": "PR",
  "rhode island": "RI",
  "south carolina": "SC",
  "south dakota": "SD",
  "tennessee": "TN",
  "texas": "TX",
  "utah": "UT",
  "vermont": "VT",
  "virginia": "VA",
  "virgin islands": "VI",
  "washington": "WA",
  "west virginia": "WV",
  "wisconsin": "WI",
  "wyoming": "WY",
}

export const labelForLocation = (primaryLocation) => {
  if (primaryLocation?.msa) {
    return primaryLocation.msa.replace("MSA: ", "")
  } else if (primaryLocation?.county) {
    return primaryLocation.county
      .replace("COUNTY: ", "")
      .replace(" (", ", ")
      .replace(")", "")
  } else if (primaryLocation?.city && primaryLocation.region1) {
    let abbreviated_region

    if (primaryLocation.region1.toLowerCase() in stateAbbreviations) {
      abbreviated_region = stateAbbreviations[primaryLocation.region1.toLowerCase()]
    } else {
      abbreviated_region = primaryLocation.region1
    }

    return `${primaryLocation.city}, ${abbreviated_region}`
  } else {
    return ""
  }
}


export const locationGeoDifference = (firstLocation, secondLocation) => {
  if (firstLocation?.lat && firstLocation?.long && secondLocation?.lat && secondLocation.long) {
    return [firstLocation.lat - secondLocation.lat, firstLocation.long - secondLocation.long]
  } else {
    return [null, null]
  }
}

export const areLocationsDifferent = (firstLocation, secondLocation, tol=1/3600) => {
  const [d1, d2] = locationGeoDifference(firstLocation, secondLocation)
  if (d1 && d2) {
    return Math.abs(d1) > tol || Math.abs(d2) > tol
  } else {
    return null
  }
}

export const removeDuplicateLocations = (firstLocation, otherLocations) => {
  if (Array.isArray(otherLocations)) {
    return otherLocations.filter((ol) => areLocationsDifferent(firstLocation, ol))
  }
}

