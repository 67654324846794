import PropTypes from "prop-types";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoIcon from '@mui/icons-material/Info';
import {Box, Button, Card, Tooltip, Typography} from "@mui/material";
import styled from "@emotion/styled";
import React from "react";

const TooltipTypography = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 16,
}));


const MultiSourceDataPointChip = (props) => {
  const dataPointName =
    props.dataPointName ? props.dataPointName : "data point"

  const phrasingPermuation = () => {
    if (props?.alternateDataPoints == null) {
      return ""
    } else {
      return props?.alternateDataPoints?.length > 0 ? " all alternates/variations of" : ""
    }
  }

  const allVariationsString =
    [props.primaryDataPoint].concat(props.alternateDataPoints).join(", ")

  const iconSize =
    Math.round(20 * props.scale)

  const dataPointMargins =
    props.dataPointMargins ? props.dataPointMargins : Math.round(8 * props.scale)

  return (
    <Card
      variant={"outlined"}
      sx={{
        display: "inline-flex",
        textAlign: "left",
        borderRadius: "16px",
        paddingX: "10px",
        paddingY: "3px",
        alignItems: "center",
        ml: props.ml,
        mr: props.mr
      }}
    >
      <Tooltip
          title={
            <TooltipTypography sx={{textTransform: "capitalize"}}>
              {dataPointName}
            </TooltipTypography>
          }
          placement={"top"}
          arrow
      >
        {props.iconLink
          ? <Button
              size="small"
              href={props.iconLink}
              target="_blank"
              sx={{padding: "0px", margin: "0px", minWidth: "auto"}}
            >
              {props.iconRenderer({height: iconSize, width: iconSize, color: "#58595B"})}
            </Button>
          : props.iconRenderer({height: iconSize, width: iconSize, color: "#58595B"})
        }

      </Tooltip>
      <Box
        sx={{
          marginX: `${dataPointMargins}px`,
          maxHeight: iconSize,
          maxWidth: props.maxDataWidth,
        }}
      >
        <Typography noWrap variant={"body2"}>
          {props.primaryDataPoint}
        </Typography>
      </Box>
      { props.copyEnabled
        ?
          <>
            <Tooltip
                title={
                  <TooltipTypography>
                    {`Copy${phrasingPermuation()} the ${dataPointName} to the clipboard`}
                  </TooltipTypography>
                }
                placement={"top"}
                arrow
            >
              <Button
                onClick={() => navigator.clipboard.writeText(allVariationsString)}
                size="small"
                endIcon={<ContentCopyIcon fontSize="inherit"/>}
                sx={{padding: "0px", margin: "0px", minWidth: "auto"}}
              >
                {(props?.alternateDataPoints && props?.alternateDataPoints?.length > 0) ? `+${props.alternateDataPoints.length}` : ""}
              </Button>
            </Tooltip>
          </>
        :
          <></>
      }
    </Card>
  )
}

const DefaultIconRenderer = (settings) => {
  const {height, width, color} = settings
  return (
    <InfoIcon
      sx={{
        color: color,
        height: height,
        width: width
      }}
    />
  )
}


MultiSourceDataPointChip.propTypes = {
  primaryDataPoint: PropTypes.string.isRequired,
  alternateDataPoints: PropTypes.arrayOf(PropTypes.string),
  iconRenderer: PropTypes.func,  // supply (settings) => Icon/SVGIcon
  scale: PropTypes.number,
  typographyVariant: PropTypes.string,
  maxDataWidth: PropTypes.any,
  ml: PropTypes.any,
  mr: PropTypes.any,
  dataPointMargins: PropTypes.any,
  dataPointName: PropTypes.string,
  iconLink: PropTypes.string,
  copyEnabled: PropTypes.bool,
}

MultiSourceDataPointChip.defaultProps = {
  alternateDataPoints: [],
  iconRenderer: DefaultIconRenderer,
  scale: 1.0,
  typographyVariant: "caption",
  maxDataWidth: "250px",
  ml: "0px",
  mr: "0px",
  copyEnabled: true
}

export default MultiSourceDataPointChip