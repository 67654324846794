import React, {useEffect, useState} from "react";
import {MainStyledTypography, StyledTypography,} from "../layouts/SubHeader/SubHeaderStyled";
import {Grid} from "@mui/material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
  DividerGridStyle,
  ItemGrid,
  RecentStyledGrid,
  Search,
  SearchContainer,
  SearchIconWrapper,
  StyledInputBase,
} from "./LandingSubHeaderStyled";
import {useNavigate} from "react-router-dom";
import {randomHexString} from "../../helper/util";
import Menu from "@mui/material/Menu";
import {getRecentlyViewedCompanies} from "../../api/companyApi";
import {useAuth} from "../AuthProvider";
import CompanyMenuItem from "../Common/CompanyMenuItem";

const LandingSubHeader = () => {

  const [, setSearchValue] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [recentlyViewedIds, setRecentlyViewedIds] = useState([])

  const navigate = useNavigate();
  const {token} = useAuth();

  const isRecentlyViewedMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    getRecentlyViewedCompanies(token)
      .then(r => {
        if (r.status === 200) {
          setRecentlyViewedIds(r.data)
        } else {
          console.log(r)
          setRecentlyViewedIds([])
        }
      })
  }, [])

  const handleRecentlyViewedButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRecentlyViewedMenuClose = () => {
    setAnchorEl(null);
  };

  let searchValueData;
  const handleSearch = (event) => {
    searchValueData = event.target.value;
  };

  const handleKeyDown = async (event) => {
    // setLoading(true);
    setSearchValue(searchValueData);
    if (event.key === "Enter") {
      if (searchValueData?.trim()) {
        const spec = {query: {
          TermsInclude: searchValueData.split(" ")
        }}
        const searchKey = randomHexString(10)
        sessionStorage.setItem(searchKey, JSON.stringify(spec))
        navigate(`/lists/companies/${searchKey}`);
      }
    }
  };

  return (
    <>
      <Grid
        position={"relative"}
        sx={{
          background: "#F6F4FF",
          zIndex: "1",
          "@media (max-width: 600px)": {
            padding: "0px 15px",
          },
        }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <ItemGrid>
          <Grid
            container
            flexDirection={"column"}
            style={{ padding: "40px 0px 0px 0px" }}
            alignItems={"center"}
            justifyContent={"center"}
            gap={"36px"}
          >
            <Grid item>
              <Grid container>
                <Grid item>
                  <StyledTypography>
                    Find what you 're looking for with
                  </StyledTypography>
                </Grid>
                <Grid item>
                  <MainStyledTypography>
                    &nbsp; AlphaSearch.
                  </MainStyledTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "-32px",
              }}
            >
              <SearchContainer
                style={{ position: "relative" }}
                container
                alignItems={"center"}
              >
                {/*<Search onClick={() => handleRecentSearch()} item>*/}
                <Search item>
                  <SearchIconWrapper>
                    <img
                      src="/Image/search-normal.svg"
                      width="32px"
                      height="32px"
                      alt="alt"
                    />
                    {/* <SearchIcon sx={{ color: "#4764F6" }} /> */}
                  </SearchIconWrapper>
                  <StyledInputBase
                    value={searchValueData}
                    onChange={(event) => handleSearch(event)}
                    onKeyDown={handleKeyDown}
                    placeholder="Search a term, industry, or a specific company"
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>
                <DividerGridStyle />
                <Grid
                  style={{
                    "@media (max-width:375px)": {
                      display: "flex",
                    },
                  }}
                  item
                >
                  <Grid
                    container
                    columnGap={"16px"}
                    rowGap={"5px"}
                    flexWrap={"initial"}
                  >
                    <RecentStyledGrid
                      item
                      id="recently-viewed-buttonlike"
                      aria-controls={isRecentlyViewedMenuOpen ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={isRecentlyViewedMenuOpen ? 'true' : undefined}
                      onClick={handleRecentlyViewedButtonClick}
                    >
                      Recently viewed
                    </RecentStyledGrid>
                    <Grid item>
                      <ScheduleIcon style={{ fill: "rgb(88, 89, 91,0.3)" }} />
                    </Grid>
                    <Menu
                      id="recently-viewed-companies-menu"
                      anchorEl={anchorEl}
                      open={isRecentlyViewedMenuOpen}
                      onClose={handleRecentlyViewedMenuClose}
                      MenuListProps={{
                        'aria-labelledby': 'recently-viewed-buttonlike',
                      }}
                    >
                      { recentlyViewedIds?.map( (rvId) => {
                        return (<CompanyMenuItem id={rvId}/>)
                      })}
                    </Menu>
                  </Grid>
                </Grid>
              </SearchContainer>
            </Grid>
          </Grid>
        </ItemGrid>
      </Grid>
    </>
  );
};

export default LandingSubHeader;
