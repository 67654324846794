import {authenticated_api, unauthenticated_api} from "./base";

export const userInvite = async (token, email) => {
  try {
    if (token) {
      return await authenticated_api.post("/admin/invite_user/",
        {email: email},
        {headers: {Authorization: `Bearer ${token}`}}
      );
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};



export const userSignup = async (userdata) => {
  try {
    const config = {headers: {"Content-Type": "application/json"}};
    return await unauthenticated_api.post(`/user/signup/`, userdata, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const adminSignup = async (token, admindata) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    };
    return await authenticated_api.post(
      `/admin/signup_admin/`,
      admindata,
      config
    );
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

// TODO: Remove this
export const validateToken = async (token) => {
  try {
    return await unauthenticated_api.get(`/admin/validate_token/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const validateInviteCode = async (code) => {
  try {
    return await authenticated_api.post(
      `/admin/validate_invite_code/`,
      {invite_code: code},
      {headers: {"Content-Type": "application/json"}});
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const setTemporaryPassword = async (token, payload) => {
  try {
    if (token) {
      return await authenticated_api.post("/admin/set_temporary_password/",
        payload,
        {headers: {Authorization: `Bearer ${token}`}}
      );
    }
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
