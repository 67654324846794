import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  AccountBalance,
  AutoStories,
  Category,
  Explore,
  HolidayVillage,
  Insights,
  JoinInner,
  PeopleAlt,
  Public
} from "@mui/icons-material";
import {LocationCard} from "../Common/LocationCard";
import {NarrativeCard} from "../Common/NarrativeCard";
import {SectorDisplayCard} from "../Common/SectorDisplayCard";
import {CompanyPropTypes} from "../../schema/company";
import {HqChip} from "../Common/HqChip";
import PersonCard from "../Common/PersonCard";
import {DollarFormatter, EmployeeFormatter, optBoolToDes} from "../../helper/formatting";
import CompanyListCard from "../Common/CompanyListCard";
import NoInfoCard from "../Common/NoInfoCard";
import MetricCard from "../Viz/MetricCard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import GroupsIcon from "@mui/icons-material/Groups";
import {useTheme} from "@mui/material";
import {removeDuplicateLocations} from "../../helper/address";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{display: "flex"}}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, flexGrow: 1, overflowY: "auto", maxHeight: "calc(100vh - 500px)", width: "565px"}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function ModalVerticalTabs(props) {
  const {companyDetail} = props

  const [value, setValue] = React.useState(0);

  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let filtered;
  if (companyDetail?.otherLocations && companyDetail.otherLocations.length > 0) {
    filtered = removeDuplicateLocations(companyDetail?.primaryLocation, companyDetail?.otherLocations)
  } else {
    filtered = null
  }

  return (
    <Box
      sx={{bgcolor: 'background.paper', display: 'flex'}}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab
            label={
              <Box sx={{ display: 'inline-flex', flexWrap: 'nowrap' }}>
                <Explore/>
                <Typography variant={"h5"} sx={{pl: "4px"}}>
                  Location
                </Typography>
              </Box>
            }
            sx={{
              alignItems: "flex-start", textTransform: "none", pl: 0
            }}
            {...a11yProps(0)}
        />
        <Tab
            label={
              <Box sx={{ display: 'inline-flex', flexWrap: 'nowrap' }}>
                <Category/>
                <Typography variant={"h5"} sx={{pl: "4px"}}>
                  Classification
                </Typography>
              </Box>
            }
            sx={{
              alignItems: "flex-start",  textTransform: "none", pl: 0
            }}
            {...a11yProps(1)}
        />
        <Tab
            label={
              <Box sx={{ display: 'inline-flex', flexWrap: 'nowrap' }}>
                <Insights/>
                <Typography variant={"h5"} sx={{pl: "4px"}}>
                  Metrics
                </Typography>
              </Box>
            }
            sx={{
              alignItems: "flex-start",  textTransform: "none", pl: 0
            }}
            {...a11yProps(2)}
        />
        <Tab
            label={
              <Box sx={{ display: 'inline-flex', flexWrap: 'nowrap' }}>
                <AutoStories/>
                <Typography variant={"h5"} sx={{pl: "4px"}}>
                  Descriptive
                </Typography>
              </Box>
            }
            sx={{
              alignItems: "flex-start",  textTransform: "none", pl: 0
            }}
            {...a11yProps(3)}
        />
        <Tab
            label={
              <Box sx={{ display: 'inline-flex', flexWrap: 'nowrap' }}>
                <AccountBalance/>
                <Typography variant={"h5"} sx={{pl: "4px"}}>
                  Incorporation
                </Typography>
              </Box>
            }
            sx={{
              alignItems: "flex-start",  textTransform: "none", pl: 0
            }}
            {...a11yProps(4)}
        />
        <Tab
            label={
              <Box sx={{ display: 'inline-flex', flexWrap: 'nowrap' }}>
                <PeopleAlt/>
                <Typography variant={"h5"} sx={{pl: "4px"}}>
                  People
                </Typography>
              </Box>
            }
            sx={{
              alignItems: "flex-start",  textTransform: "none", pl: 0
            }}
            {...a11yProps(5)}
        />
        <Tab
            label={
              <Box sx={{ display: 'inline-flex', flexWrap: 'nowrap' }}>
                <Public/>
                <Typography variant={"h5"} sx={{pl: "4px"}}>
                  Socioeconomic
                </Typography>
              </Box>
            }
            sx={{
              alignItems: "flex-start",  textTransform: "none", pl: 0
            }}
            {...a11yProps(6)}
        />
        <Tab
            label={
              <Box sx={{ display: 'inline-flex', flexWrap: 'nowrap' }}>
                <JoinInner/>
                <Typography variant={"h5"} sx={{pl: "4px"}}>
                  Similar
                </Typography>
              </Box>
            }
            sx={{
              alignItems: "flex-start",  textTransform: "none", pl: 0
            }}
            {...a11yProps(7)}
        />
        <Tab
            label={
              <Box sx={{ display: 'inline-flex', flexWrap: 'nowrap' }}>
                <HolidayVillage/>
                <Typography variant={"h5"} sx={{pl: "4px"}}>
                  Franchise
                </Typography>
              </Box>
            }
            sx={{
              alignItems: "flex-start",  textTransform: "none", pl: 0
            }}
            {...a11yProps(8)}
        />
      </Tabs>

      {/*Location Panel*/}
      <TabPanel value={value} index={0} sx={{minHeight: "100"}}>
        <Typography sx={{pb: "8px"}} variant={"h6"}>
          Primary address: {(props.isHq) ? HqChip : <></>}
        </Typography>
        { (companyDetail?.primaryLocation)
          ?
            <LocationCard
              location={companyDetail?.primaryLocation}
              mb={"10px"}
              expanded={true}
            />
          :
            <NoInfoCard/>
        }
        { (filtered && filtered.length > 0) &&
          <>
            <Typography sx={{pb: "8px"}} variant={"h6"}>
              Other addresses:
            </Typography>
            {filtered.map(function(adr) {
              return(<LocationCard location={adr} mb={"10px"} expanded={false}/>)
            })}
          </>
        }
      </TabPanel>

      {/*Classification Panel*/}
      <TabPanel value={value} index={1}>
        <Typography sx={{pb: "8px"}} variant={"h6"}>
          From online sources:
        </Typography>
        {companyDetail?.sourcedClassifications
          ? companyDetail?.sourcedClassifications?.map((classification) => {
              return(<SectorDisplayCard levels={classification.levels}/>)
            })
          : <NoInfoCard/>
        }
        <Typography sx={{pt: "8px", pb: "8px"}} variant={"h6"}>
          AlphaSearch AI:
        </Typography>
        {companyDetail?.modeledClassifications && companyDetail.modeledClassifications.length > 0
          ? companyDetail?.modeledClassifications?.map((classification) => {
              return(<SectorDisplayCard levels={classification.levels}/>)
            })
          : <NoInfoCard/>
        }
      </TabPanel>

      {/*Metrics Panel*/}
      <TabPanel value={value} index={2}>
        <Box
          sx={{display: "grid", gridTemplateColumns: "repeat(1fr)", gap: 3, justifyContent: "center"}}
        >
          <Box item>
            <MetricCard {...{
              metricName: "Revenue per Employee",
              iconRenderer: (settings) =>
                <AttachMoneyIcon
                    sx={{
                      width: settings.width,
                      height: settings.height,
                      color: theme.palette.motifs.money.dark
                    }}
                />,
              iconBackground: theme.palette.motifs.money.light,
              pointEstimate: companyDetail?.revenuePerEmployee?.pointEstimate,
              lowerBound: companyDetail?.revenuePerEmployee?.lowerBound,
              upperBound: companyDetail?.revenuePerEmployee?.upperBound,
              dataPoints: companyDetail?.revenuePerEmployee?.dataPoints,
              numberFormatter: DollarFormatter,
              width: "350px"
            }}/>
          </Box>
          <Box item>
            <MetricCard {...{
              metricName: "Headcount (Total)",
              iconRenderer: (settings) =>
                <GroupsIcon
                    sx={{
                      width: settings.width,
                      height: settings.height,
                      color: theme.palette.motifs.headcount.dark
                    }}
                />,
              iconBackground: theme.palette.motifs.headcount.light,
              pointEstimate: companyDetail?.totalHeadcount?.pointEstimate,
              lowerBound: companyDetail?.totalHeadcount?.lowerBound,
              upperBound: companyDetail?.totalHeadcount?.upperBound,
              dataPoints: companyDetail?.totalHeadcount?.dataPoints,
              numberFormatter: EmployeeFormatter,
              width: "350px",
            }}/>
          </Box>
          <Box item>
            <MetricCard {...{
              metricName: "Headcount (FTE)",
              iconRenderer: (settings) =>
                <GroupsIcon
                    sx={{
                      width: settings.width,
                      height: settings.height,
                      color: theme.palette.motifs.headcount.dark
                    }}
                />,
              iconBackground: theme.palette.motifs.headcount.light,
              pointEstimate: companyDetail?.fteHeadcount?.pointEstimate,
              lowerBound: companyDetail?.fteHeadcount?.lowerBound,
              upperBound: companyDetail?.fteHeadcount?.upperBound,
              dataPoints: companyDetail?.fteHeadcount?.dataPoints,
              numberFormatter: EmployeeFormatter,
              width: "350px",
            }}/>
          </Box>
          <Box item>
            <MetricCard {...{
              metricName: "Headcount (Online)",
              iconRenderer: (settings) =>
                <GroupsIcon
                    sx={{
                      width: settings.width,
                      height: settings.height,
                      color: theme.palette.motifs.headcount.dark
                    }}
                />,
              iconBackground: theme.palette.motifs.headcount.light,
              pointEstimate: companyDetail?.onlineHeadcount?.pointEstimate,
              lowerBound: companyDetail?.onlineHeadcount?.lowerBound,
              upperBound: companyDetail?.onlineHeadcount?.upperBound,
              dataPoints: companyDetail?.onlineHeadcount?.dataPoints,
              numberFormatter: EmployeeFormatter,
              width: "350px",
            }}/>
          </Box>
        </Box>
      </TabPanel>

      {/*Descriptive Panel*/}
      <TabPanel value={value} index={3}>
        <Typography sx={{pb: "8px"}} variant={"h6"}>
          Key words/terms/phrases:
        </Typography>
        { companyDetail?.keywords ? (
          <NarrativeCard description={companyDetail.keywords.join(", ")}/>
        ) : (
          <NoInfoCard/>
        )}

        <Typography sx={{pt: "8px", pb: "8px"}} variant={"h6"}>
          Descriptions from online sources:
        </Typography>
        { companyDetail?.descriptive ? (
          companyDetail?.descriptive?.map((narrative) => {
              return(<NarrativeCard description={narrative}/>)
          })
        ) : (
          <NoInfoCard/>
        )}
      </TabPanel>

      {/*Incorporation Panel*/}
      <TabPanel value={value} index={4}>
        <Box
          sx={{
            maxWidth: "500px",
            display: 'grid',
            gap: 2,
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridTemplateRows: 'auto',
          }}
        >
          <Box sx={{textAlign: "left"}}>
            <Typography variant="h6">
              Domicile
            </Typography>
          </Box>
          <Box sx={{textAlign: "right"}}>
            <Typography variant="body1">
              {companyDetail?.incorporation?.domicile}
            </Typography>
          </Box>
          <Box sx={{textAlign: "left"}}>
            <Typography variant="h6">
              Legal Form
            </Typography>
          </Box>
          <Box sx={{textAlign: "right"}}>
            <Typography variant="body1">
              {companyDetail?.incorporation?.legalForm}
            </Typography>
          </Box>
          <Box sx={{textAlign: "left"}}>
            <Typography variant="h6">
              Marketablility
            </Typography>
          </Box>
          <Box sx={{textAlign: "right"}}>
            <Typography variant="body1">
              {companyDetail?.incorporation?.marketability}
            </Typography>
          </Box>
          <Box sx={{textAlign: "left"}}>
            <Typography variant="h6">
              For Profit?
            </Typography>
          </Box>
          <Box sx={{textAlign: "right"}}>
            <Typography variant="body1">
              {optBoolToDes(companyDetail?.incorporation?.forProfit)}
            </Typography>
          </Box>
        </Box>
      </TabPanel>

      {/*People Panel*/}
      <TabPanel value={value} index={5}>
        { (companyDetail?.people && companyDetail.people?.length > 0)
          ?
            companyDetail?.people?.map(function(person) {
              return(<PersonCard {...person}/>)
            })
          :
            <NoInfoCard/>
        }
      </TabPanel>

      {/*Socioeconomic Panel*/}
      <TabPanel value={value} index={6}>
        <Box
          sx={{
            maxWidth: "500px",
            display: 'grid',
            gap: 2,
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridTemplateRows: 'auto',
          }}
        >
          <Box sx={{textAlign: "left"}}>
            <Typography variant="h6">
              Hispanic or Latino
            </Typography>
          </Box>
          <Box sx={{textAlign: "right"}}>
            <Typography variant="body1">
              {optBoolToDes(companyDetail?.socioeconomic?.hispanicOrLatino)}
            </Typography>
          </Box>

          <Box sx={{textAlign: "left"}}>
            <Typography variant="h6">
              Female
            </Typography>
          </Box>
          <Box sx={{textAlign: "right"}}>
            <Typography variant="body1">
              {optBoolToDes(companyDetail?.socioeconomic?.female)}
            </Typography>
          </Box>

          <Box sx={{textAlign: "left"}}>
            <Typography variant="h6">
              Veteran
            </Typography>
          </Box>
          <Box sx={{textAlign: "right"}}>
            <Typography variant="body1">
              {optBoolToDes(companyDetail?.socioeconomic?.veteran)}
            </Typography>
          </Box>

          <Box sx={{textAlign: "left"}}>
            <Typography variant="h6">
              Minority
            </Typography>
          </Box>
          <Box sx={{textAlign: "right"}}>
            <Typography variant="body1">
              {optBoolToDes(companyDetail?.socioeconomic?.minority)}
            </Typography>
          </Box>

          <Box sx={{textAlign: "left"}}>
            <Typography variant="h6">
              Hubzone
            </Typography>
          </Box>
          <Box sx={{textAlign: "right"}}>
            <Typography variant="body1">
              {optBoolToDes(companyDetail?.socioeconomic?.hubzone)}
            </Typography>
          </Box>

          <Box sx={{textAlign: "left"}}>
            <Typography variant="h6">
              Low/Moderate Income
            </Typography>
          </Box>
          <Box sx={{textAlign: "right"}}>
            <Typography variant="body1">
              {optBoolToDes(companyDetail?.socioeconomic?.lowOrModerateIncome)}
            </Typography>
          </Box>

          <Box sx={{textAlign: "left"}}>
            <Typography variant="h6">
              Rural
            </Typography>
          </Box>
          <Box sx={{textAlign: "right"}}>
            <Typography variant="body1">
              {optBoolToDes(companyDetail?.socioeconomic?.rural)}
            </Typography>
          </Box>
        </Box>
      </TabPanel>

      {/*Similar Companies Panel*/}
      <TabPanel value={value} index={7}>
        {companyDetail?.similarCompanies?.map((id) => {
          return <CompanyListCard id={id}/>
        })}
      </TabPanel>

      {/*Franchise Panel*/}
      <TabPanel value={value} index={8}>
        {companyDetail?.franchise?.franchiseName ? (
          <>
            <Typography variant={"h6"}>
              This entity operates franchises of the following type(s):
            </Typography>
            <br/>
            <Typography variant={"body1"}>
              {companyDetail?.franchise?.franchiseName}
            </Typography>
          </>
        ) : (
          <NoInfoCard/>
        )}
      </TabPanel>
    </Box>
  );
}

ModalVerticalTabs.propTypes = {
  companyDetail: CompanyPropTypes
}