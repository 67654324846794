import SubHeader from "../../components/layouts/SubHeader";
import React, {useEffect, useState} from "react";
import MainLayout from "../../components/layouts/MainLayout";
import {Box, Divider, Grid, Typography, useMediaQuery} from "@mui/material";
import styled from "@emotion/styled";
import {useParams} from "react-router-dom";
import CompanyDetailSection from "./CompanyDetailSection";
import {getCompanyDetail, registerCompanyInquiry} from "../../api/companyApi";
import {useAuth} from "../../components/AuthProvider";
import {addBackground} from "../../helper/image_processing";

import 'jimp/browser/lib/jimp';
import defaultImageUrl from "../../assets/defaultImage.svg";
import MultiSourceDataPointChip from "../../components/Common/MultiSourceDataPointChip";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LanguageIcon from "@mui/icons-material/Language";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {SocialMediaBar} from "../../components/Common/SocialMediaBar";
import {removeDuplicateLocations} from "../../helper/address";
import {HqChip} from "../../components/Common/HqChip";
import {LocationCard} from "../../components/Common/LocationCard";
import NoInfoCard from "../../components/Common/NoInfoCard";
import {SectorDisplayCard} from "../../components/Common/SectorDisplayCard";
import {NarrativeCard} from "../../components/Common/NarrativeCard";
import {DollarFormatter, EmployeeFormatter, optBoolToDes} from "../../helper/formatting";
import CompanyListCard from "../../components/Common/CompanyListCard";
import CompanyDetailGrid from "./CompanyDetailGrid";
import PersonCard from "../../components/Common/PersonCard";
import {listMembershipForCompany} from "../../api/list";
import ListMembershipCard from "../../components/Common/ListMembershipCard";
import AddToListModal from "../../components/AddToListModal";
import MetricCard from "../../components/Viz/MetricCard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import GroupsIcon from "@mui/icons-material/Groups";
import {useTheme} from "@emotion/react";
import CompanyDetailMetricGrid from "./CompanyDetailMetricGrid";
import NoCompanySpecified from "./NoCompanySpecified";

const { Jimp } = window;  // See https://github.com/jimp-dev/jimp/issues/1194

const delay = ms => new Promise((resolve) => setTimeout(resolve, ms));

const Wrapper = styled(Grid)(() => ({
  padding: "102px 0px 0px 0px",
  //  background: "#F2F3F4",
  "@media (max-width: 900px)": {
    // Styles for screens with a maximum width of 768px
    padding: "66px 0px 0px 0px",
  },
}));

export const CompanyDetailPage = () => {

  const [selectedCompanyData, setSelectedCompanyData] = useState(null);
  const [imageData, setImageData] = useState(defaultImageUrl);
  const [listMembership, setListMembership] = useState([]);
  const [addToListModalOpen, setAddToListModalOpen] = useState(false);
  const [listUpdateCounter, setListUpdateCounter] = useState(0);

  const {stagedId} = useParams();
  const {token} = useAuth();
  const theme = useTheme();

  useEffect(() => {
    const handleSelectedCompanyChange = () => {
      if (stagedId) {
        getCompanyDetail(token, stagedId)
          .then(response => {
            setSelectedCompanyData(response?.data);
          })
          .then(registerCompanyInquiry(token, stagedId))
      }
    }
    handleSelectedCompanyChange()
  }, [stagedId]);

  useEffect(() => {
    const onListChangeRequiringRefresh = () => {
      if (stagedId) {
        listMembershipForCompany(token, stagedId)
          .then(response => {
            if (response?.status === 200) {
              setListMembership(response?.data);
            }
          })
      }
    }
    onListChangeRequiringRefresh()
  }, [stagedId, listUpdateCounter, addToListModalOpen]);

  useEffect(() => {
      const getCompanyLogo = async (uri) => {
        if (uri) {
        // We don't want to use zoominfo image processing...
        const unprocessed_uri = uri.replace("w_70,h_70,c_fit", "")

        await Jimp.read(unprocessed_uri)
          .then(image => image
            .autocrop()
            .scaleToFit(70, 70))
          .then(processed =>
            processed.getBase64Async(Jimp.MIME_PNG))
          .then(imageString => {
            addBackground(imageString, 74, 74)
            .then(withBg => {
              if (withBg) {
                setImageData(withBg)
              } else {
                setImageData(imageString)
              }
            })
          })
          .catch((err) => {
            console.log(err)
          });
        }
      }
      getCompanyLogo(selectedCompanyData?.primaryLogo)
    }, [selectedCompanyData]);

    let filtered;
    if (selectedCompanyData?.otherLocations && selectedCompanyData.otherLocations.length > 0) {
      filtered = removeDuplicateLocations(selectedCompanyData?.primaryLocation, selectedCompanyData?.otherLocations)
    } else {
      filtered = null
    }

  const handleAddToList = () => {
    setAddToListModalOpen(true);
  }

  const matchesXl = useMediaQuery(theme.breakpoints.up('cdXl'));
  const metricCardWidth = matchesXl ? "275px" : "275px"

  return (
    <MainLayout>
      <Wrapper>
        <SubHeader/>
        <AddToListModal
          open={addToListModalOpen}
          onClose={() => setAddToListModalOpen(false)}
          stagedId={selectedCompanyData?.stagedId}
          onAdded={() => {
            delay(500).then(() => {setListUpdateCounter(listUpdateCounter + 1)});
          }}
        />
        <Box sx={{marginY: "25px", marginX: "50px"}}>
          { selectedCompanyData
            ?
              <Box
                sx={{flexGrow: 1}}
              >
                <Grid container gap={"13px"} alignItems={"center"} sx={{mb: "25px"}}>
                  <Grid item>
                    <img
                      src={imageData}
                      height="74px"
                      width="74px"
                      alt="logo"
                      style={{
                        borderRadius: "12px",
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Grid item>
                      <Typography variant={"h1"}>
                        {selectedCompanyData?.primaryName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 100px",
                    gap: 0,
                    alignItems: "center",
                  }}
                >
                  <Box sx={{pb: "5px"}}>
                    {selectedCompanyData?.primaryTelephone &&
                      <MultiSourceDataPointChip
                        dataPointName={"phone number"}
                        primaryDataPoint={selectedCompanyData?.primaryTelephone}
                        alternateDataPoints={selectedCompanyData?.alternateTelephones}
                        maxDataWidth={"120px"}
                        mr={"8px"}
                        iconRenderer={
                          (props) => {
                            return (
                              <LocalPhoneIcon
                                sx={{
                                  color: props.color,
                                  height: props.height,
                                  width: props.width
                                }}
                              />
                            )
                          }
                        }
                      />
                    }
                    {selectedCompanyData?.primaryWebsite &&
                      <MultiSourceDataPointChip
                        dataPointName={"website"}
                        primaryDataPoint={selectedCompanyData?.primaryWebsite}
                        alternateDataPoints={selectedCompanyData?.alternateWebsites}
                        iconLink={
                          selectedCompanyData?.primaryWebsite.includes("http", 0)
                            ? selectedCompanyData?.primaryWebsite
                            : `https://${selectedCompanyData?.primaryWebsite}`}
                        maxDataWidth={"250px"}
                        mr={"8px"}
                        iconRenderer={
                          (props) => {
                            return (
                              <LanguageIcon
                                sx={{
                                  color: props.color,
                                  height: props.height,
                                  width: props.width
                                }}
                              />
                            )
                          }
                        }
                      />
                    }
                    {selectedCompanyData?.stagedId &&
                      <MultiSourceDataPointChip
                        primaryDataPoint={selectedCompanyData?.stagedId}
                        dataPointMargins={"0px"}
                        dataPointName={"Alphasearch ID"}
                        maxDataWidth={"200px"}
                        mr={"8px"}
                        iconRenderer={
                          (props) => {
                            return (
                              <FingerprintIcon
                                sx={{
                                  color: props.color,
                                  height: props.height,
                                  width: props.width
                                }}
                              />
                            )
                          }
                        }
                      />
                    }
                    <MultiSourceDataPointChip
                      primaryDataPoint={"Add to List"}
                      dataPointMargins={"25px"}
                      dataPointName={"Add to List"}
                      maxDataWidth={"250px"}
                      copyEnabled={false}
                      mr={"8px"}
                      iconRenderer={
                        (props) => {
                          return (
                            <AddCircleOutlineIcon
                              onClick={handleAddToList}
                              sx={{
                                color: props.color,
                                height: props.height,
                                width: props.width,
                                mr: "5px"
                              }}
                            />
                          )
                        }
                      }
                    />
                  </Box>
                  <Box
                    sx={{
                      alignItems: "center",
                      alignContent: "center",
                      alignSelf: "center",
                      justifySelf: "center",
                      justifyContent: "center",
                      justifyItems: "center",
                      pb: "5px"
                    }}
                  >
                    <SocialMediaBar
                      links={{
                        linkedin: selectedCompanyData?.social?.linkedinUrl,
                        instagram: selectedCompanyData?.social?.instagramUrl,
                        twitter: selectedCompanyData?.social?.twitterUrl,
                        facebook: selectedCompanyData?.social?.facebookUrl,
                      }}
                      justifyContent={"flex-end"}
                      iconSize="24px"
                    />
                  </Box>
                </Box>

                <Divider sx={{marginTop: "0px", marginBottom: "0px"}}/>

                <CompanyDetailGrid>

                  {/* Location */}
                  <Grid sx={{gridArea: "location"}}>
                    <CompanyDetailSection title="Location" padding={"15px"}>
                      <Typography sx={{pb: "8px"}} variant={"h6"}>
                        Primary address: {(selectedCompanyData.isHq) ? HqChip : <></>}
                      </Typography>
                      { (selectedCompanyData?.primaryLocation)
                        ?
                          <LocationCard
                            location={selectedCompanyData?.primaryLocation}
                            mb={"10px"}
                            expanded={true}
                          />
                        :
                          <NoInfoCard/>
                      }
                      { (filtered && filtered.length > 0) &&
                        <>
                          <Typography sx={{pb: "8px"}} variant={"h6"}>
                            Other addresses:
                          </Typography>
                          {filtered.map(function(adr) {
                            return(<LocationCard location={adr} mb={"10px"} expanded={false}/>)
                          })}
                        </>
                      }
                    </CompanyDetailSection>
                  </Grid>

                  {/* Classification */}
                  <Grid sx={{gridArea: "classification"}}>
                    <CompanyDetailSection title="Classification" padding={"15px"}>
                      <Typography sx={{pb: "8px"}} variant={"h6"}>
                        From online sources:
                      </Typography>
                      {selectedCompanyData?.sourcedClassifications
                        ? selectedCompanyData?.sourcedClassifications?.map((classification) => {
                            return(<SectorDisplayCard levels={classification.levels}/>)
                          })
                        : <NoInfoCard/>
                      }
                      <Typography sx={{pt: "8px", pb: "8px"}} variant={"h6"}>
                        AlphaSearch AI:
                      </Typography>
                      {selectedCompanyData?.modeledClassifications && selectedCompanyData.modeledClassifications.length > 0
                        ? selectedCompanyData?.modeledClassifications?.map((classification) => {
                            return(<SectorDisplayCard levels={classification.levels}/>)
                          })
                        : <NoInfoCard/>
                      }
                    </CompanyDetailSection>
                  </Grid>


                  {/* Descriptive */}
                  <Grid sx={{gridArea: "descriptive"}}>
                    <CompanyDetailSection title="Descriptive" padding={"15px"}>
                      <Typography sx={{pb: "8px"}} variant={"h6"}>
                        Key words/terms/phrases:
                      </Typography>
                      { selectedCompanyData?.keywords ? (
                        <NarrativeCard description={selectedCompanyData.keywords.join(", ")}/>
                      ) : (
                        <NoInfoCard/>
                      )}
                      <Typography sx={{pt: "8px", pb: "8px"}} variant={"h6"}>
                        Descriptions from online sources:
                      </Typography>
                      { selectedCompanyData?.descriptive ? (
                        selectedCompanyData?.descriptive?.map((narrative) => {
                            return(<NarrativeCard description={narrative}/>)
                        })
                      ) : (
                        <NoInfoCard/>
                      )}
                    </CompanyDetailSection>
                  </Grid>

                  {/* Similar Companies */}
                  <Grid sx={{gridArea: "similar"}}>
                    <CompanyDetailSection title="Similar Companies" padding={"15px"}>
                      {selectedCompanyData?.similarCompanies?.map((id) => {
                        return <CompanyListCard id={id}/>
                      })}
                    </CompanyDetailSection>
                  </Grid>

                  {/* People */}
                  <Grid sx={{gridArea: "people"}}>
                    <CompanyDetailSection title="People" padding={"15px"}>
                      { (selectedCompanyData?.people && selectedCompanyData.people?.length > 0)
                        ?
                          selectedCompanyData?.people?.map(function(person) {
                            return(<PersonCard {...person}/>)
                          })
                        :
                          <NoInfoCard/>
                      }
                    </CompanyDetailSection>
                  </Grid>

                  {/* Incorporation */}
                  <Grid sx={{gridArea: "incorporation"}}>
                    <CompanyDetailSection title="Incorporation" padding={"15px"}>
                      <Box
                        sx={{
                          maxWidth: "500px",
                          display: 'grid',
                          gap: 2,
                          gridTemplateColumns: 'repeat(2, 1fr)',
                          gridTemplateRows: 'auto',
                        }}
                      >
                        <Box sx={{textAlign: "left"}}>
                          <Typography variant="h6">
                            Domicile
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "right"}}>
                          <Typography variant="body1">
                            {selectedCompanyData?.incorporation?.domicile}
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "left"}}>
                          <Typography variant="h6">
                            Legal Form
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "right"}}>
                          <Typography variant="body1">
                            {selectedCompanyData?.incorporation?.legalForm}
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "left"}}>
                          <Typography variant="h6">
                            Marketablility
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "right"}}>
                          <Typography variant="body1">
                            {selectedCompanyData?.incorporation?.marketability}
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "left"}}>
                          <Typography variant="h6">
                            For Profit?
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "right"}}>
                          <Typography variant="body1">
                            {optBoolToDes(selectedCompanyData?.incorporation?.forProfit)}
                          </Typography>
                        </Box>
                      </Box>
                    </CompanyDetailSection>
                  </Grid>

                  {/* Socioeconomic */}
                  <Grid sx={{gridArea: "socioeconomic"}}>
                    <CompanyDetailSection title="Socioeconomic" padding={"15px"}>
                      <Box
                        sx={{
                          maxWidth: "500px",
                          display: 'grid',
                          gap: 2,
                          gridTemplateColumns: 'repeat(2, 1fr)',
                          gridTemplateRows: 'auto',
                        }}
                      >
                        <Box sx={{textAlign: "left"}}>
                          <Typography variant="h6">
                            Hispanic or Latino
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "right"}}>
                          <Typography variant="body1">
                            {optBoolToDes(selectedCompanyData?.socioeconomic?.hispanicOrLatino)}
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "left"}}>
                          <Typography variant="h6">
                            Female
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "right"}}>
                          <Typography variant="body1">
                            {optBoolToDes(selectedCompanyData?.socioeconomic?.female)}
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "left"}}>
                          <Typography variant="h6">
                            Veteran
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "right"}}>
                          <Typography variant="body1">
                            {optBoolToDes(selectedCompanyData?.socioeconomic?.veteran)}
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "left"}}>
                          <Typography variant="h6">
                            Minority
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "right"}}>
                          <Typography variant="body1">
                            {optBoolToDes(selectedCompanyData?.socioeconomic?.minority)}
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "left"}}>
                          <Typography variant="h6">
                            Hubzone
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "right"}}>
                          <Typography variant="body1">
                            {optBoolToDes(selectedCompanyData?.socioeconomic?.hubzone)}
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "left"}}>
                          <Typography variant="h6">
                            Low/Moderate Income
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "right"}}>
                          <Typography variant="body1">
                            {optBoolToDes(selectedCompanyData?.socioeconomic?.lowOrModerateIncome)}
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "left"}}>
                          <Typography variant="h6">
                            Rural
                          </Typography>
                        </Box>
                        <Box sx={{textAlign: "right"}}>
                          <Typography variant="body1">
                            {optBoolToDes(selectedCompanyData?.socioeconomic?.rural)}
                          </Typography>
                        </Box>
                      </Box>
                    </CompanyDetailSection>
                  </Grid>

                  {/* Franchise */}
                  <Grid sx={{gridArea: "franchise"}}>
                    <CompanyDetailSection title="Franchise" padding={"15px"}>
                      {selectedCompanyData?.franchise?.franchiseName ? (
                        <>
                          <Typography variant={"h6"}>
                            This entity operates franchises of the following type(s):
                          </Typography>
                          <br/>
                          <Typography variant={"body1"}>
                            {selectedCompanyData?.franchise?.franchiseName}
                          </Typography>
                        </>
                      ) : (
                        <NoInfoCard/>
                      )}
                    </CompanyDetailSection>
                  </Grid>

                  {/* My Lists */}
                  <Grid sx={{gridArea: "lists"}}>
                    <CompanyDetailSection title="My Lists" padding={"15px"}>
                      {listMembership ? (
                        <>
                          <Typography variant={"h6"}>
                            This entity is a member of the following lists:
                          </Typography>
                          <br/>
                          { listMembership?.map( l => {
                            return(
                              <ListMembershipCard
                                list={l}
                                stagedId={stagedId}
                                onRemove={() =>{
                                  delay(500).then(setListUpdateCounter(listUpdateCounter + 1))
                                }}
                              />
                            )
                          })}
                        </>
                      ) : (
                        <NoInfoCard/>
                      )}
                    </CompanyDetailSection>
                  </Grid>
                </CompanyDetailGrid>
                  <CompanyDetailMetricGrid>
                    <Grid item sx={{gridArea: "rev"}}>
                      <MetricCard {...{
                        metricName: "Revenue",
                        iconRenderer: (settings) =>
                          <AttachMoneyIcon
                              sx={{
                                width: settings.width,
                                height: settings.height,
                                color: theme.palette.motifs.money.dark
                              }}
                          />,
                        iconBackground: theme.palette.motifs.money.light,
                        pointEstimate: selectedCompanyData?.revenue?.pointEstimate,
                        lowerBound: selectedCompanyData?.revenue?.lowerBound,
                        upperBound: selectedCompanyData?.revenue?.upperBound,
                        dataPoints: selectedCompanyData?.revenue?.dataPoints,
                        numberFormatter: DollarFormatter,
                        width: metricCardWidth
                      }}/>
                    </Grid>
                    <Grid item sx={{gridArea: "hc"}}>
                      <MetricCard {...{
                        metricName: "Headcount",
                        iconRenderer: (settings) =>
                          <GroupsIcon
                              sx={{
                                width: settings.width,
                                height: settings.height,
                                color: theme.palette.motifs.headcount.dark
                              }}
                          />,
                        iconBackground: theme.palette.motifs.headcount.light,
                        pointEstimate: selectedCompanyData?.harmonizedHeadcount?.pointEstimate,
                        lowerBound: selectedCompanyData?.harmonizedHeadcount?.lowerBound,
                        upperBound: selectedCompanyData?.harmonizedHeadcount?.upperBound,
                        dataPoints: selectedCompanyData?.harmonizedHeadcount?.dataPoints,
                        numberFormatter: EmployeeFormatter,
                        width: metricCardWidth
                      }}/>
                    </Grid>
                    <Grid item sx={{gridArea: "rpe"}}>
                      <MetricCard {...{
                        metricName: "Revenue per Employee",
                        iconRenderer: (settings) =>
                          <AttachMoneyIcon
                              sx={{
                                width: settings.width,
                                height: settings.height,
                                color: theme.palette.motifs.money.dark
                              }}
                          />,
                        iconBackground: theme.palette.motifs.money.light,
                        pointEstimate: selectedCompanyData?.revenuePerEmployee?.pointEstimate,
                        lowerBound: selectedCompanyData?.revenuePerEmployee?.lowerBound,
                        upperBound: selectedCompanyData?.revenuePerEmployee?.upperBound,
                        dataPoints: selectedCompanyData?.revenuePerEmployee?.dataPoints,
                        numberFormatter: DollarFormatter,
                        width: metricCardWidth
                      }}/>
                    </Grid>
                      <Grid item sx={{gridArea: "hctot"}}>
                        <MetricCard {...{
                          metricName: "Headcount (Total)",
                          iconRenderer: (settings) =>
                            <GroupsIcon
                                sx={{
                                  width: settings.width,
                                  height: settings.height,
                                  color: theme.palette.motifs.headcount.dark
                                }}
                            />,
                          iconBackground: theme.palette.motifs.headcount.light,
                          pointEstimate: selectedCompanyData?.totalHeadcount?.pointEstimate,
                          lowerBound: selectedCompanyData?.totalHeadcount?.lowerBound,
                          upperBound: selectedCompanyData?.totalHeadcount?.upperBound,
                          dataPoints: selectedCompanyData?.totalHeadcount?.dataPoints,
                          numberFormatter: EmployeeFormatter,
                          width: metricCardWidth
                        }}/>
                      </Grid>
                    <Grid item sx={{gridArea: "hcfte"}}>
                      <MetricCard {...{
                        metricName: "Headcount (FTE)",
                        iconRenderer: (settings) =>
                          <GroupsIcon
                              sx={{
                                width: settings.width,
                                height: settings.height,
                                color: theme.palette.motifs.headcount.dark
                              }}
                          />,
                        iconBackground: theme.palette.motifs.headcount.light,
                        pointEstimate: selectedCompanyData?.fteHeadcount?.pointEstimate,
                        lowerBound: selectedCompanyData?.fteHeadcount?.lowerBound,
                        upperBound: selectedCompanyData?.fteHeadcount?.upperBound,
                        dataPoints: selectedCompanyData?.fteHeadcount?.dataPoints,
                        numberFormatter: EmployeeFormatter,
                        width: metricCardWidth
                      }}/>
                    </Grid>
                    <Grid item gridArea="hcweb">
                      <MetricCard {...{
                        metricName: "Headcount (Online)",
                        iconRenderer: (settings) =>
                          <GroupsIcon
                              sx={{
                                width: settings.width,
                                height: settings.height,
                                color: theme.palette.motifs.headcount.dark
                              }}
                          />,
                        iconBackground: theme.palette.motifs.headcount.light,
                        pointEstimate: selectedCompanyData?.onlineHeadcount?.pointEstimate,
                        lowerBound: selectedCompanyData?.onlineHeadcount?.lowerBound,
                        upperBound: selectedCompanyData?.onlineHeadcount?.upperBound,
                        dataPoints: selectedCompanyData?.onlineHeadcount?.dataPoints,
                        numberFormatter: EmployeeFormatter,
                        width: metricCardWidth
                      }}/>
                    </Grid>
                  </CompanyDetailMetricGrid>

              </Box>
            :
              <Box sx={{width: 1, display: "flex", justifyContent: "center"}}>
                <NoCompanySpecified/>
              </Box>
          }
        </Box>
      </Wrapper>
    </MainLayout>
  )
}