import React from 'react';
import styled from "@emotion/styled";
import {Dialog, DialogTitle, Grid, Typography} from "@mui/material";
import {Code} from "@mui/icons-material";

const StyledDialog = styled(Dialog)(() => ({}));

const StyledDialogTitle = styled(DialogTitle)(() => ({

}));
const StyledGrid = styled(Grid)(() => ({
  minWidth: "450px",
  minHeight: "250px",
  padding: "30px",
}));

const StyledTypography = styled(Typography)(() => ({}));


function ComingSoonDialog(props) {
  const { onClose, open } = props;

  return (
    <StyledDialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} PaperProps={{ sx: { borderRadius: "8px" } }}>
      <StyledDialogTitle id="simple-dialog-title">
        <StyledTypography variant={"mainText"}>
          Feature Under Development
        </StyledTypography>
      </StyledDialogTitle>
      <StyledGrid container>
        <Grid item>
          <Code sx={{ fontSize: 120, color: "#4A2CF5"}} />
        </Grid>
        <Grid item >
          <StyledTypography variant={"mainSubText"}>
            This feature is planned for a forthcoming release. Stay tuned!
          </StyledTypography>
        </Grid>
      </StyledGrid>
    </StyledDialog>
  );
}

export default ComingSoonDialog;