import styled from "@emotion/styled";
import {Box, Card, IconButton, Typography, useTheme} from "@mui/material";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import React from "react";
import {removeCompanyFromList} from "../../api/list";
import {useAuth} from "../AuthProvider";


const delay = ms => new Promise((resolve) => setTimeout(resolve, ms));

styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 16,
}));

const CardBoundary = styled(Card)(() => ({
  border: "1px solid rgba(88, 89, 91, 0.2)",
  borderRadius: "8px",
  width: "500px",
  padding: "15px",
  transition: "transform 0.15s ease-in-out",
}));

const ListMembershipCard = (props) => {
  const {
    list,
    stagedId = null,
    onRemove = () => {}
  } = props;

  const theme = useTheme();
  const {token} = useAuth()

  const name = list?.name ? list.name : "(Unknown Name)"
  const subtitle = (list?.description) ? list?.description : ""
  const companyCount = list?.companies?.length ? ` (${list?.companies?.length} total companies)` : ""
  const title = `${name}${companyCount}`

  const handleRemoveFromList = () => {
    removeCompanyFromList(token, list?._id, stagedId)
      .then(() => {})

    delay(500).then(onRemove())
  }

  return (
    <CardBoundary variant="outlined" sx={{mb: "10px"}}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: " 40px repeat(4, 1fr)",
          gridTemplateAreas: `"icon title title title remove"
          " icon subtitle subtitle subtitle remove"`
        }}
      >
        <Box sx={{gridArea: "title"}}>
          <Typography variant={"h6"}>{title}</Typography>
        </Box>
        <Box sx={{gridArea: "subtitle"}}>
          <Typography variant={"caption"}>{subtitle}</Typography>
        </Box>
        <Box sx={{gridArea: "icon", display: "flex", justifyContent: "left", alignItems: "center"}}>
          <ArticleOutlinedIcon sx={{color: theme.palette.widget.main}} />
        </Box>
        <Box sx={{gridArea: "remove", display: "flex", justifyContent: "right", alignItems: "center"}}>
            <IconButton sx={{display: stagedId ? "block" : "none"}} >
              <RemoveCircleOutlineOutlinedIcon onClick={handleRemoveFromList} />
            </IconButton>
        </Box>
      </Box>
    </CardBoundary>

)}

export default ListMembershipCard