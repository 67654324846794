import React, {useEffect, useState} from "react";
import {Button, Grid, Typography,} from "@mui/material";
import styled from "@emotion/styled";
import {useNavigate, useParams} from "react-router-dom";
import LabelText from "../../components/Common/LabelText";
import LoginLeftPart from "../../components/LoginLeftPart";
import {validateCompany, validateConfirmPassword, validateFirst, validateLast, validatePassword}
  from "../../helper/validation";
import TextFieldBox from "../../components/Common/TextFieldBox";
import {userSignup, validateInviteCode} from "../../api/adminApi";

const MainContainer = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const StyledTypography = styled(Typography)(() => ({
  textAlign: "center",
  marginTop: "5rem",
}));

const LoginTypography = styled(Typography)(() => ({
  color: "#FFFFFF",
  fontWeight: 600,
  fontSize: "16px",
  textTransform: "initial",
}));

const ValidationTypography = styled(Typography)(() => ({
  color: "red",
  fontWeight: 600,
  fontSize: "16px",
}));

// TODO: Delete this once no longer referenced
export const ErrorMessage = styled("div")(() => ({
  marginTop: "5px",
  marginBottom: "8px",
  color: "red",
  fontSize: "12px",
}));

const Signup = () => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [companyName, setCompanyName] = useState();

  const [validationError, setValidationError] = useState();

  const { inviteCode } = useParams();


  useEffect( () => {
    validateInviteCode(inviteCode)
      .then((res) => {
          if (res.status === 200) {
            setEmail(res?.data?.email)
          } else {
            console.log(res);
          }
        }
      )
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = [
      validateFirst(firstName),
      validateLast(lastName),
      validatePassword(password),
      validateConfirmPassword(password, confirmPassword),
      validateCompany(companyName),
    ].filter((e) => e !== "");

    if (errors.length > 0) {
      setValidationError(errors[0]);
    } else {
      setValidationError("")

      const userdata = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        company: companyName,
        invite_code: inviteCode
      };

      //  API Call or dispatch action
      const res = await userSignup(userdata);
      if (res) {
        if (res.status === 200) {
          navigate("/");
        } else {
          console.log(res)
          setValidationError(res.data?.detail)
        }
      }
    }
  };


  const handlePasswordInputChange = (e) => {
    setPassword(e.target.value);
    setValidationError(validatePassword(e.target.value));
  }

  const handleConfirmPasswordInputChange = (e) => {
    setConfirmPassword(e.target.value);
    setValidationError(validateConfirmPassword(password, e.target.value))
  }

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value)
    setValidationError(validateFirst(e.target.value))
  }

  const handleLastNameChange = (e) => {
    setLastName(e.target.value)
    setValidationError(validateLast(e.target.value))
  }

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value)
    setValidationError(validateCompany(e.target.value))
  }

  return (
    <MainContainer>
      <Grid
        style={{
          margin: "auto",
          width: "611px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="forgot_div">
          <div className="wrap_login_first_part">
            <Typography variant="mainText" mb={"11px"} component={"p"}>
              Create Your Account
            </Typography>

            <Grid style={{minHeight: "50px"}}>
              <ValidationTypography>{validationError}</ValidationTypography>
            </Grid>

            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid mb={"14px"}>
                    <LabelText label={"First name"} isRequired={true}/>
                  </Grid>
                  <TextFieldBox sx={{mb: 2, fontSize: "16px"}}
                    key={"first_name_text_field"}
                    required
                    fullWidth
                    placeholder={"First name"}
                    name={"first_name"}
                    handleChange={handleFirstNameChange}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid mb={"14px"}>
                    <LabelText label={"Last name"} isRequired={true}/>
                  </Grid>
                  <TextFieldBox sx={{mb: 2, fontSize: "16px"}}
                    key={"last_name_text_field"}
                    required
                    fullWidth
                    placeholder={"Last name"}
                    name={"last_name"}
                    handleChange={handleLastNameChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid mb={"14px"}>
                    <LabelText label={"Email"} isRequired={false}/>
                  </Grid>
                  <TextFieldBox sx={{mb: 2, fontSize: "16px"}}
                    key={"email_text_field"}
                    required
                    fullWidth
                    disabled
                    placeholder={"Email"}
                    name={"email"}
                    value={email}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid mb={"14px"}>
                    <LabelText label={"New Password"} isRequired={true}/>
                  </Grid>
                  <TextFieldBox sx={{mb: 2, fontSize: "16px"}}
                    key={"new_password_text_field"}
                    required
                    fullWidth
                    type={"password"}
                    placeholder={"New Password*"}
                    name={"new_password"}
                    handleChange={handlePasswordInputChange}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid mb={"14px"}>
                    <LabelText label={"Confirm Password"} isRequired={true}/>
                  </Grid>
                  <TextFieldBox sx={{mb: 2, fontSize: "16px"}}
                    key={"confirm_password_text_field"}
                    required
                    fullWidth
                    type={"password"}
                    placeholder={"Confirm Password*"}
                    name={"confirm_password"}
                    handleChange={handleConfirmPasswordInputChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid mb={"14px"}>
                    <LabelText label={"Company name"} isRequired={true}/>
                  </Grid>
                  <TextFieldBox sx={{mb: 2, fontSize: "16px"}}
                    key={"company_name_text_field"}
                    required
                    fullWidth
                    placeholder={"Company name"}
                    name={"company_name"}
                    handleChange={handleCompanyNameChange}
                  />
                </Grid>

                <Grid item xs={12} mt={"33px"}>
                  <Grid>
                    <Button
                      variant="contained"
                      fullWidth
                      className="loginButton"
                      onClick={handleSubmit}
                    >
                      <LoginTypography>Submit</LoginTypography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

            </form>
          </div>
        </div>
      </Grid>
      <LoginLeftPart />
    </MainContainer>
  )
}

export default Signup;