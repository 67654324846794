import styled from "@emotion/styled";
import {Grid, InputBase} from "@mui/material";

export const ItemGrid = styled(Grid)`
  &::before {
    content: "";
    position: absolute;
    background: url("/Image/subheader.svg");
    width: 128px;
    height: 157px;
    left: 0;
  }
  
  &::after {
    content: "";
    position: absolute;
    background: url("/Image/sub-header-right-img.png") no-repeat;
    width: 230px;
    height: 157px;
    right: 0px;
    top: 0;
  }
`;

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: "1",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.9, 1.9, 1.9, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4.6)})`,
    transition: theme.transitions.create("width"),
    width: "650px",
    background: "#FFFFFF",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "25px",
    borderRadius: "6px solid #58595B",
    "@media (max-width:1443px)": {
      maxWidth: "650px",
      width: "100%",
    },
  },
}));

export const SearchContainer = styled(Grid)`
  background: #ffffff;
  border: 1px solid rgba(88, 89, 91, 0.3);
  box-shadow: 0px 4px 14px rgba(74, 44, 245, 0.2);
  border-radius: 8px;
  max-width: 1035px;
  width: 100%;
  height: 80px;
  justify-content: space-between;
  padding-right: 25px;
  @media (max-width: 900px) {
    max-width: 600px;
  }
  @media (max-width: 600px) {
    max-width: 400px;
    padding-right: 0px;
    flex-wrap: nowrap;
  }
`;

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  alignItems: "center",
}));

export const RecentStyledGrid = styled(Grid)`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #58595b;
  cursor: pointer;
`;

export const DividerGridStyle = styled(Grid)`
  display: inline-block;
  height: 71px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 20px;
`;