import {
  Chip,
  Grid,




} from "@mui/material";
import styled from "@emotion/styled";
export const Card = styled(Grid)(() => ({
  color: "#3c3c3c",
  padding: "26px 25px",
  margin: "6px -2px",
  display: "flex",
  gap: 15,
  background: "#FFFFFF",
  border: "1px solid rgba(88, 89, 91, 0.2)",
  borderRadius: "10px",
  boxShadow: " 0 -1px 3px 1px #D7DBDD",
  width: "100%",
  minWidth: 600,
}));
styled(Grid)(() => ({
  display: "flex",
  gap: "50px",
  mt: 2,
  alignItems: "center",
  justifyContent: "space-between",
  fontWeight: 700,
}));

export const ChipCard = styled(Chip)(() => ({
  backgroundColor: "#FFF2E6",
  fontWeight: 500,
  fontSize: "16px",
  color: "#CE6300",
  lineHeight: "20px",
  paddingLeft: "14px",
}));

export const ChipCardSecond = styled(Chip)(() => ({
  backgroundColor: "#FFE6FF",
  fontWeight: 500,
  fontSize: "16px",
  color: "#9900CE",
  lineHeight: "20px",
  paddingRight: "8px",
  paddingLeft: "20px",
}));

export const BlackDotGrid = styled(Grid)`
  background: #000000;
  width: 6px;
  height: 6px;
  border-radius: 50%;
`;
