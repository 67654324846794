// Pattern from:
// https://dev.to/sanjayttg/jwt-authentication-in-react-with-react-router-1d03

// This allows our children components to import and use the state of [token] and [role].

import { createContext, useContext, useEffect, useMemo, useState } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {

  const [token, setToken_] = useState(localStorage.getItem("authToken"));
  const [role, setRole_] = useState(localStorage.getItem("role"));
  const [userFirstName, setUserFirstName_] = useState(localStorage.getItem("userFirstName"));

  const setToken = (newToken) => {
    setToken_(newToken);
  };

  const setRole = (newRole) => {
    setRole_(newRole);
  };

  const setUserFirstName = (newFirstName) => {
    setUserFirstName_(newFirstName);
  };

  useEffect(() => {
    if (token) {
      // axios.defaults.headers.Common["Authorization"] = "Bearer " + token;
      localStorage.setItem('authToken', token);
      localStorage.setItem('role', role);
      localStorage.setItem('userFirstName', userFirstName);
    } else {
      // delete axios.defaults.headers.Common["Authorization"];
      localStorage.removeItem('authToken')
      localStorage.removeItem('role')
      localStorage.removeItem('userFirstName');
    }
  }, [token]);

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      token,
      setToken,
      role,
      setRole,
      userFirstName,
      setUserFirstName,
    }),
    [token]
  );

  // Provide the authentication context to the children components
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;