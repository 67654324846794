import React, {useEffect, useState} from "react";
import SendInvite from "./SendInvite";
import {Button, Divider, Typography} from "@mui/material";
import UserCreateOrEditModal from "./UserCreateOrEditModal";
import {AddCircle, DeleteOutlined, Edit, FaceRetouchingOffOutlined, PasswordOutlined} from "@mui/icons-material";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import {deleteUser, getUserList} from "../../../api/userApi";
import {useAuth} from "../../../components/AuthProvider";

export const blankUserData = {first_name: "", last_name: "", email: "", company: "", role: 2}

export const UserManagement = () => {
  const [createOrUpdateUserMode, setCreateOrUpdateUserMode] = useState('closed');
  const [userList, setUserList] = useState({});
  const [selectedUserData, setSelectedUserData] = useState(blankUserData)
  const [crudCounter, setCrudCounter] = useState(0);

  const {token} = useAuth();

  useEffect(() => {
    const loadUsers = async () => {
      const token = localStorage.getItem("authToken");
      // We will just get the whole list; let the MUI datagrid handle pagination:
      const res = await getUserList(token, 0, 1000);
      if (res) {
        if (res?.status === 200) {
          setUserList(res?.data?.[0])
        } else {
          console.log(res)
        }
      }
    };
    loadUsers();
  }, [crudCounter]);

  const handleDeleteClick = async (id) => {
    const email = userList.filter((x) => x._id === id)?.[0]?.email
    if (email === "" || email === undefined || email === null) {
      console.log("User deletion request malformed.")
    } else {
      const res = await deleteUser(token, email);
      if (res) {
        if (res?.status === 200) {
          setUserList(userList.filter((x) => x._id !== id))
          setCrudCounter(crudCounter + 1)
        } else {
          console.log(res)
        }
      }
    }
  }

  const handleEditUserClick = (id) => {
    const matchingUser = userList.filter((x) => x._id === id)
    if (matchingUser.length === 1) {
      // flatten and parse role info
      const flattened = {...matchingUser[0], ...{role: matchingUser[0].role.role_id}}
      setSelectedUserData(flattened)
      setCreateOrUpdateUserMode('update')
    } else {
      console.log("Attempted to edit user that is not found.")
    }
  }

  const handleSetPassword = (id) => {
    const matchingUser = userList.filter((x) => x._id === id)
    if (matchingUser.length === 1) {
      // flatten and parse role info
      const flattened = {...matchingUser[0], ...{role: matchingUser[0].role.role_id}}
      setSelectedUserData(flattened)
      setCreateOrUpdateUserMode('password')
    } else {
      console.log("Attempted to set the password for a user that is not found.")
    }
  };

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      width:300,
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      flex: 1,
      sortable: true,
    },
    {
      field: "first_name",
      headerName: "Name",
      width: 300,
      flex: 1,
      sortable: true,
    },
    {
      field: "company",
      headerName: "Company",
      width: 200,
      flex: 1,
      sortable: true,
    },
    {
      field: "role_name",
      headerName: "Role",
      width: 500,
      flex: 1,
      sortable: true,
      valueGetter: (data) => data.row.role.role_name,
    },
    {
      field: "is_active",
      headerName: "Is_Active",
      width: 300,
      flex: 1,
      sortable: true,
    },
    {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 200,
    cellClassName: 'actions',
    getActions: ({ id }) => {
      return [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => handleEditUserClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<PasswordOutlined />}
          label="Set Password"
          onClick={() => handleSetPassword(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<FaceRetouchingOffOutlined />}
          label="Deactivate"
          onClick={() => {}}
          color="inherit"
          disabled={true}
        />,
        <GridActionsCellItem
          icon={<DeleteOutlined />}
          label="Delete"
          onClick={() => handleDeleteClick(id)}
          color="inherit"
        />,
      ]
    }
    }
  ];

  return (
    <>
      <SendInvite/>
      <Divider/>
      <Button
        variant="contained"
        endIcon={<AddCircle/>}
        sx={{marginY: "14px"}}
        onClick={() => {
          setSelectedUserData(blankUserData)
          setCreateOrUpdateUserMode('create')
        }}
      >
        <Typography variant={"buttonFace"}>New User</Typography>
      </Button>
      <DataGrid
        columns={columns}
        rows={userList}
        rowHeight={35}
        getRowId={(data) => data._id}
      />
      <UserCreateOrEditModal
        mode={createOrUpdateUserMode}
        initialUserData={selectedUserData}
        onClose={() => {
          setCreateOrUpdateUserMode('closed')
          setCrudCounter(crudCounter + 1)
        }}
      />
    </>
  )
}